import styled from 'styled-components';

export const Wrapper = styled.div`
  .modalDeleteMessage {
    &::after {
      content: '';
      background-color: #00000080;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100vw;
      height: 100vh;
    }
  }
`;
