import React from "react";

import { Tags } from "./styles";

export default function Tag({ cor, children, small, select }) {
  return (
    <Tags cor={cor} small={small} select={select}>
      {children}
    </Tags>
  );
}
