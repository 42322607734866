import React from 'react';
import useDadosSakStore from '../../../../../stores/useDadosSakStore';
import { getToken } from '../../../../../utils/usefulFunctions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function DeleteRepositoryImageModal({
  id,
  getRepositoryImages,
}) {
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const { t, i18n } = useTranslation();

  const deleteRepositoryImage = (e, imgId) => {
    e.stopPropagation();

    const token = getToken();
    fetch('https://chat.sak.com.br/imagem.php', {
      method: 'DELETE',
      body: JSON.stringify({
        token: token,
        userchat: userLogado.userchat,
        images: [{ id: imgId }],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        getRepositoryImages();

        toast.success(t('Arquivo removido com sucesso'));
        const $ = window.jQuery;
        const container = $($(`#modalRepositoryImage-${id}`)[0]);
        container.modal('hide');
      })
      .catch((err) => alert(err));
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-danger"
        data-toggle="modal"
        data-target={`#modalRepositoryImage-${id}`}
        data-backdrop="false"
        onClick={(e) => {
          e.stopPropagation();
          const $ = window.jQuery;
          $(`#modalRepositoryImage-${id}`).modal('show');
          $(`.modal-backdrop`).css('width', '0');
        }}
      >
        <img src="assets/images/trashcan.svg" alt="trash" />
      </button>

      <div
        className="modal fade modalRepositoryImage"
        id={`modalRepositoryImage-${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalRepositoryImageLabel-${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5>{t('Deseja apagar esse arquivo?')}</h5>
            </div>
            <div className="modal-footer" style={{ borderTop: 'none' }}>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {t('Cancelar')}
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => deleteRepositoryImage(e, id)}
              >
                {t('Apagar')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
