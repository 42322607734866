import { create } from 'zustand';

const useRenderStore = create((set) => ({
  triggerRender: false,
  lastRender: new Date().getTime(),
  shouldRender: () => set((state) => ({ triggerRender: true })),
  forceRender: () =>
    set((state) => ({
      triggerRender: false,
      lastRender: new Date().getTime(),
    })),
}));

export default useRenderStore;
