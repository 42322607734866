import { useEffect, useState, useRef } from 'react';
import {
  AudioControl,
  StopButton,
  RecordButton,
  AudioPlayerWrapper,
} from './styles';

var recorder;
const RecordAudio = () => {
  // const recorder = useRef(null); //Recorder
  const audioPlayer = useRef(null); //Ref for HTML Audio tag
  const timer = useRef(null); //Ref for interval object
  const time = useRef(0); //Stores the value of time
  const displayTime = useRef(null); //Stores dom ref for div to be used to display time
  const displayTimeOnAudioPlayer = useRef(null);

  const [isRecording, setIsRecording] = useState(null);
  const [play, setPlay] = useState(false);

  const showAudioPlayer = () => {
    const target = document.querySelector('.audio--player');
    target.style.display = 'block';
    target.setAttribute('data-play-audio-button', true);
  };
  const hideAudioPlayer = () => {
    const target = document.querySelector('.audio--player');
    target.pause();
    target.style.display = 'none';
    target.setAttribute('data-play-audio-button', false);
  };

  const handleRecord = () => {
    let audio = document.querySelector('.audio--player');
    console.log({ audio });
    // Request permissions to record audio
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      hideAudioPlayer();
      recorder = new MediaRecorder(stream);

      // Set record to <audio> when recording will be finished
      recorder.addEventListener('dataavailable', (e) => {
        audio.src = URL.createObjectURL(e.data);

        var audioFile = new File([e.data], 'my_file.weba', {
          type: 'audio/webm',
        });

        window.myDropzoneGlobal.addFile(audioFile);
      });

      // Start recording
      recorder.start();
      setIsRecording(true);
    });
  };

  const handleStop = () => {
    showAudioPlayer();
    // Stop recording
    recorder.stop();
    setIsRecording(false);
    // Remove “recording” icon from browser tab
    recorder.stream.getTracks().forEach((i) => i.stop());
  };

  const startTimer = () => {
    //sets interval that updates time on UI every second
    time.current = 0;
    timer.current = setInterval(() => {
      const secondCounter = time.current % 60;
      const minuteCounter = Math.floor(time.current / 60);
      let computedSecond =
        String(secondCounter).length === 1
          ? `0${secondCounter}`
          : secondCounter;
      let computedMinute =
        String(minuteCounter).length === 1
          ? `0${minuteCounter}`
          : minuteCounter;

      time.current = time.current + 1;
      displayTime.current.innerText = `${computedMinute}: ${computedSecond}`;
      //Displays time by updating the DOM directly
    }, 1000);
  };

  const stopTimer = () => {
    displayTime.current.innerText = '';
    clearInterval(timer.current);
  };

  const setTime = () => {
    if (audioPlayer.current) {
      displayTimeOnAudioPlayer.current.innerText = Math.floor(
        audioPlayer.current.duration
      );
      //Displays time by updating the DOM directly
      //Note: Math.floor is used since audio time is in decimal and player only displays floor values
      //eg 3.86 will be displayed as 3 seconds in player
    }
  };

  useEffect(() => {
    //start/stops timer depending on recording state
    if (isRecording) {
      startTimer();
    } else {
      stopTimer();
    }
  }, [isRecording]);

  return (
    <div>
      {/* <button onClick={handleRecord}>record</button>
      <button onClick={handleStop}>stop</button>
      <audio className="audio--player" controls></audio> */}

      <AudioControl>
        <StopButton
          type="button"
          isRecording={isRecording}
          onClick={handleStop}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 24 24"
            fill="none"
            stroke="red"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-stop-circle"
          >
            <circle cx="12" cy="12" r="10" />
            <rect x="9" y="9" width="6" height="6" />
          </svg>
        </StopButton>
        <RecordButton
          type="button"
          isRecording={isRecording}
          onClick={handleRecord}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#5b5e61af"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-mic"
          >
            <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z" />
            <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
            <line x1="12" y1="19" x2="12" y2="23" />
            <line x1="8" y1="23" x2="16" y2="23" />
          </svg>
        </RecordButton>
        <span ref={displayTime} style={{ marginRight: '8px' }}>
          {timer.current}
        </span>

        <AudioPlayerWrapper hide={document.documentElement.clientWidth < 680}>
          <audio
            className="audio--player"
            style={{ display: 'none' }}
            ref={displayTimeOnAudioPlayer}
            // src={blobURL}
            controls="controls"
            onLoadedMetadata={setTime} //fethches metadata info like duration
            onTimeUpdate={setTime} //event handler whenever time progresses on player
            onEnded={() => setPlay(false)} //event handler when audio has stopped playing
          />
        </AudioPlayerWrapper>
      </AudioControl>
    </div>
  );
};
export default RecordAudio;
