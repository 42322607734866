export default class CacheStore {
  constructor(cacheKeyPrefix = null) {
    this.cacheKeyPrefix = cacheKeyPrefix;
  }
  isNumber(value) {
    return !isNaN(parseFloat(value)) && !isNaN(value - 0);
  }
  isDate(value) {
    return value instanceof Date && !isNaN(value.valueOf());
  }
  put(key, value, ttl = null) {
    let createdAt = new Date();
    let expiresAt = null;

    if (this.isNumber(ttl)) {
      let date = new Date();
      date.setSeconds(date.getSeconds() + ttl);
      expiresAt = date.toISOString();
    }

    if (this.isDate(ttl)) {
      expiresAt = ttl.toISOString();
    }

    let cachedData = {
      value,
      createdAt,
      expiresAt,
    };

    if (this.cacheKeyPrefix) {
      key = this.cacheKeyPrefix + key;
    }

    localStorage.setItem(key, JSON.stringify(cachedData));

    return value;
  }
  delete(key) {
    if (this.cacheKeyPrefix) {
      key = this.cacheKeyPrefix + key;
    }

    return localStorage.removeItem(key);
  }
  remember(key, ttl, callback) {
    let cachedData = this.get(key);

    if (cachedData) {
      return cachedData;
    }
    this.put(key, 1, ttl);
    let value = callback();

    return this.put(key, value, ttl);
  }
  rememberForever(key, callback) {
    return this.remember(key, null, callback);
  }
  get(key, defaultValue = null) {
    if (this.cacheKeyPrefix) {
      key = this.cacheKeyPrefix + key;
    }

    let localStorageItem = localStorage.getItem(key);

    if (!localStorageItem) {
      return defaultValue;
    }

    let cachedData = JSON.parse(localStorageItem);

    if (
      cachedData.expiresAt &&
      cachedData.expiresAt < new Date().toISOString()
    ) {
      return defaultValue;
    }

    return cachedData.value;
  }
}
