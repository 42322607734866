import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import Contato from '../Contato';
import MensagemBusca from '../MensagemBusca';
import Tag from '../Tags';

import MensagensAntigas from '../../components/mensagensAntigas';

import { ErrorChat } from '../../components/errors';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import { Tabs } from './styles';

import Tooltip from '../Tooltip';

import Errors from '../Errors';

import { openDark } from '../../utils/darkMode';

import Lottie from 'react-lottie';

import { gamification } from '../../utils/gamification';

import { normalizeText } from '../../utils/formatDados';
import useDadosSakStore from '../../stores/useDadosSakStore';
import useSocketStore from '../../stores/useSocketStore';
import useRenderStore from '../../stores/useRenderStore';
import { ContatosPagination } from './components/ContatosPagination';
import useMarcarLidoStore from '../../stores/useMarcarLidoStore';

function Aside({
  marcadores,
  lojas,
  mensagens,
  contatos,
  search,
  setSearch,
  tab,
  setTab,
  lastRender,
}) {
  // const { sendSocketDados } = useSocket();
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  // const { printWpp } = usePrint();
  // const { printStatus } = usePrintStatus();
  // const { contatoAtivo, setContatoAtivo } = useContatoAtivo();
  // const { marcadores: marcador, setMarcadores } = useMarcadores();
  // let { contatos: ctt, setContatos } = useContatos();
  // let { ultimoContato } = useUltimoContato();
  const { t, i18n } = useTranslation();

  const [bandeira, setBandeira] = useState('55');
  const contatosAtuais = useMemo(() => contatos, [contatos]);
  const mensagensAtuais = useMemo(() => mensagens, [mensagens]);
  const [dark, setDark] = useState(false);
  const containerListaContatos = useRef(null);
  const iconSearch = useRef(null);
  const inputSearch = useRef(null);
  const atendentesRef = useRef(null);
  const dataMensagensRef = useRef(null);
  const filterRef = useRef(null);

  const setContatos = useDadosSakStore((state) => state.setContatosStore);
  const ctt = useDadosSakStore((state) => state.contatos);
  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );
  const marcador = useDadosSakStore((state) => state.marcadores);
  const setMarcadores = useDadosSakStore((state) => state.setMarcadoresStore);
  const printStatus = useDadosSakStore((state) => state.printStatus);
  const printWpp = useDadosSakStore((state) => state.printWpp);

  const shouldRender = useRenderStore((state) => state.shouldRender);

  const [msg, setMsg] = useState('');
  const [contatosPage, setContatosPage] = useState(0);
  const [paginationQuantity, setPaginationQuantity] = useState(50);
  const [otimizationCheckbox, setOtimizationCheckbox] = useState(
    JSON.parse(localStorage.getItem('otimizationContatos')) || false
  );
  const [
    otimizationLoadOnlyContatosComNaolidasCheckbox,
    setOtimizationLoadOnlyContatosComNaolidasCheckbox,
  ] = useState(
    JSON.parse(
      localStorage.getItem('otimizationLoadOnlyContatosComNaolidasCheckbox')
    ) || false
  );
  const [whatsappRingTune, setWhatsappRingTune] = useState(
    JSON.parse(localStorage.getItem('checkboxOnReceiveMessageSound')) || false
  );
  // const { userChat } = useAtendente();
  // const { userLogado } = useUserLogado();
  // const { lojaAtivo } = useLojaAtiva();
  const [notifTab, setNotifTab] = useState({
    Novos: 0,
    Meus: 0,
    Ativos: 0,
    Todos: 0,
  });

  const userLogado = useDadosSakStore((state) => state.userLogado);
  const userChat = useDadosSakStore((state) => state.userChat);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const toggleMarcarLidoBoxAndCheckbox = useMarcarLidoStore(
    (state) => state.toggleMarcarLidoBoxAndCheckbox
  );
  const showMarcarLidoBtn = useMarcarLidoStore(
    (state) => state.showMarcarLidoBtn
  );
  const checkboxMarcadosComoLidoQuantity = useMarcarLidoStore(
    (state) => state.checkboxMarcadosComoLidoQuantity
  );
  const setToggleMarcarLidoBoxAndCheckbox = useMarcarLidoStore(
    (state) => state.setToggleMarcarLidoBoxAndCheckbox
  );

  const oneDay = 1000 * 60 * 60 * 24;
  const teenDays = ~~((Date.now() - oneDay * 10) / 1000);

  const [listDates] = useState({
    'Últimas 24 Horas': ~~((Date.now() - oneDay) / 1000),
    'Últimos 2 Dias': ~~((Date.now() - oneDay * 2) / 1000),
    'Ultimos 3 Dias': ~~((Date.now() - oneDay * 3) / 1000),
    'Ultimos 7 Dias': ~~((Date.now() - oneDay * 7) / 1000),
    'Ultimos 15 Dias': ~~((Date.now() - oneDay * 15) / 1000),
    'Ultimo Mês': ~~((Date.now() - oneDay * 30) / 1000),
    'Ultimo Semestre': ~~((Date.now() - oneDay * 180) / 1000),
    'Mostrar Tudo': 0,
  });

  const [defaultFilterDate, setDefaultFilterDate] = useState(
    localStorage?.filterDate || listDates?.['Ultimos 3 Dias']
  );

  if (msg) mensagens = msg;

  useEffect(() => {
    if (
      !ctt ||
      (ctt && !ctt[lojaAtivo]) ||
      (ctt[lojaAtivo] && !Object.values(ctt[lojaAtivo]).length)
    ) {
      setNotifTab({ Novos: 0, Meus: 0, Ativos: 0, Todos: 0 });
      return null;
    }

    const notifTabInfo = { Novos: 0, Meus: 0, Ativos: 0, Todos: 0 };

    const contact = ctt?.[lojaAtivo];

    for (let index in contact) {
      if (!contact?.[index]?.['ultimamsg']) continue;
      if (contact?.[index]?.['ultimamsg'] <= teenDays) continue;
      if (Number(contact?.[index]?.['naolidas']) === 0) continue;
      if (!contact?.[index]?.['chatid']) continue;

      contact[index].userchat = String(contact[index].userchat);
      contact[index].arquivado = String(contact[index].arquivado);
      userLogado.userchat = String(userLogado.userchat);

      if (
        contact[index].userchat === '0' &&
        contact[index].arquivado === '0' &&
        contact[index].ultimamsgtexto
      )
        notifTabInfo['Novos']++;
      if (
        userLogado.userchat &&
        contact[index].userchat === userLogado.userchat &&
        contact[index].fechado === '0' &&
        contact[index].arquivado === '0'
      )
        notifTabInfo['Meus']++;
      if (
        contact[index].userchat !== '0' &&
        contact[index].fechado === '0' &&
        contact[index].arquivado === '0'
      )
        notifTabInfo['Ativos']++;

      notifTabInfo['Todos']++;
    }

    setNotifTab({ ...notifTab, ...notifTabInfo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctt, userLogado, lojaAtivo]);

  useEffect(() => {
    if (!dark) {
      openDark();
      setDark(false);
    }
  }, [dark]);

  useEffect(() => {
    setMsg('');
  }, [lojaAtivo]);

  function updateFilterDate(e) {
    e.preventDefault();
    const { value } = e.target;
    localStorage.filterDate = value;
    setDefaultFilterDate(value);
  }

  function handleRemoveTag(id) {
    sendSocketDados({
      function: 'removerMarcador',
      data: [
        {
          id,
          userserv: lojaAtivo,
        },
      ],
    });

    const findIdMarcador = Object.values(marcador[lojaAtivo]).find(
      (item) => item.id === id
    );

    if (findIdMarcador) {
      const ArrayMarcadores = marcador;
      delete ArrayMarcadores[lojaAtivo][findIdMarcador.id];
      setMarcadores(() => ArrayMarcadores);
    }

    gamification('removerMarcador');
  }

  function handleSubmitMarcador(event) {
    event.preventDefault();

    let titulo = document.querySelector('#marcador-titulo');
    const cor = document.querySelector('#marcador-cor').value;

    if (titulo && titulo.value.length !== 0) {
      sendSocketDados({
        function: 'enviarMarcador',
        data: [
          {
            titulo: titulo.value,
            cor,
            userserv: lojaAtivo,
            userchat: userLogado.userchat,
          },
        ],
      });
      titulo.value = '';
      gamification('enviarMarcador');
    } else {
      window.notifChat('Selecione um Titulo para o seu marcador!', 'info');
    }
  }

  function handleNovaConversa(event) {
    event.preventDefault();

    const conversaNumber = document.querySelector('#contato-tel');
    const mensagem = document.querySelector('#contato-msg');

    if (!conversaNumber && !mensagem) return null;

    const conversa = conversaNumber.value.replace(/([- ()+]+)/g, '');
    if (!conversa || conversa.length <= 5) {
      window.notifChat('Digite um número válido', 'error');
      return null;
    }

    const numeroContato = `${bandeira}${conversa}@c.us`;

    const atualTimestamp = Date.now() / 1000;

    const findExistContact =
      ctt[lojaAtivo] &&
      Object.values(ctt[lojaAtivo]).find((contato) => {
        const countLengthContatoID = conversa?.length || 0;
        let numberContact = conversa;
        if (countLengthContatoID === 11) numberContact = numberContact.slice(3);
        else if (countLengthContatoID === 9)
          numberContact = numberContact.slice(1);

        const findBuscaSem9 = contato['chatid'].includes(numberContact);
        const findBuscaCom9 = contato['chatid'].includes(conversa);

        return findBuscaSem9 || findBuscaCom9;
      });

    if (findExistContact) {
      setContatoAtivo(() => findExistContact);
    } else {
      setContatos((prev) => {
        if (!prev[lojaAtivo]) return { ...prev };
        const novoContato = {
          arquivado: '0',
          atualizacao: atualTimestamp,
          carregandoConversa: false,
          chatid: numeroContato,
          conversa_id: '',
          fechado: '0',
          inclusao: atualTimestamp,
          naolidas: '0',
          nome: conversa,
          photo: '',
          photoUpdate: atualTimestamp,
          ultimamsg: atualTimestamp,
          ultimamsgtexto: mensagem.value,
          userchat: userLogado.userchat,
          userserv: lojaAtivo,
          temp: true,
        };
        prev[lojaAtivo][numeroContato] = novoContato;
        setContatoAtivo(() => novoContato);
        return { ...prev };
      }, true);
    }

    sendSocketDados({
      function: 'EnviarNovaMensagem',
      data: [
        {
          conversa: numeroContato,
          texto: mensagem.value,
          userserv: lojaAtivo,
          userchat: userLogado.userchat,
        },
      ],
    });

    mensagem.value = '';
    conversaNumber.value = '';
    document.getElementById('closeContato').click();

    shouldRender();
  }

  const clearSearch = useCallback(() => {
    if (search && inputSearch.current) {
      inputSearch.current.value = '';
      setSearch('');
    }
  }, [search]);

  const listaContatosAtendentes = useCallback(() => {
    if (!userChat) {
      return <option>Você não possui atendentes!</option>;
    } else {
      return (
        <>
          <option value="inicial">Todos</option>
          {lojas &&
            lojas.userchat &&
            Object.values(lojas.userchat).map((id) => {
              const totalAtendente = contatosAtuais
                ? Object.values(contatosAtuais).reduce(
                    (acumulador, contato) => {
                      if (
                        contato.userchat &&
                        userChat[id] &&
                        String(contato.userchat) ===
                          String(userChat[id].userchat)
                      ) {
                        return acumulador + 1;
                      }
                      return acumulador;
                    },
                    0
                  )
                : 0;
              return (
                id &&
                userChat[id] &&
                Number(userChat[id].excluido) === 0 && (
                  <option key={id} value={id}>
                    {userChat[id].nome} ({totalAtendente})
                  </option>
                )
              );
            })}
        </>
      );
    }
  }, [contatosAtuais, lojas, userChat]);

  const listaMarcadores = useCallback(() => {
    const allContatos = contatosAtuais
      ? Object.values(contatosAtuais).length
      : 0;

    if (!marcadores) return <option>Você não possui marcadores</option>;

    return (
      <>
        <option value="inicial">Todos ({allContatos})</option>
        {Object.values(marcadores).map((item) => {
          const totalMarcador = contatosAtuais
            ? Object.values(contatosAtuais).reduce((acumulador, contato) => {
                if (
                  contato.marcadores &&
                  contato.marcadores.includes(item.id)
                ) {
                  return acumulador + 1;
                }
                return acumulador;
              }, 0)
            : 0;
          return (
            <option key={item.id} value={item.id}>
              {item.titulo} ({totalMarcador})
            </option>
          );
        })}
        <option value="nenhum-marcador">{t('Sem marcadores')}</option>
      </>
    );
  }, [contatosAtuais, marcadores]);

  function validaTempoMensagem(mensagem, total) {
    if (!mensagem) return;
    const findLastTime = Object.values(mensagem).some((message) => {
      const msgDate = message?.['data_whats'] || message?.['inclusao'];
      const existContato =
        ctt[lojaAtivo] &&
        message['conversa'] &&
        ctt[lojaAtivo][message['conversa']];

      if (
        msgDate < teenDays &&
        (!search || (search && search.length < 3) || total > 500)
      )
        return false;

      return (
        (search && search.length > 3) ||
        defaultFilterDate === 0 ||
        Number(msgDate) > Number(defaultFilterDate) ||
        (existContato && Number(existContato['naolidas'])) > 0
      );
    });
    return findLastTime;
  }

  function handleFilter() {
    const filter = filterRef.current;
    const atendentes = atendentesRef.current;
    const dataMensagens = dataMensagensRef.current;
    const userChatId = atendentes.value;
    const marcadorId = filter.value;
    const dataMsg = dataMensagens.value;

    const contatosFiltrados =
      contatosAtuais && Object.values(contatosAtuais)?.length
        ? Object.values(contatosAtuais)
            ?.filter((contato) =>
              userChatId === 'inicial' ||
              (contato['userchat'] &&
                Number(contato['userchat']) === Number(userChatId))
                ? contato
                : null
            )
            ?.filter((contato) => {
              if (marcadorId === 'nenhum-marcador') {
                return !contato.marcadores;
              } else {
                return marcadorId === 'inicial' ||
                  (contato.marcadores &&
                    contato.marcadores.indexOf(marcadorId) !== -1)
                  ? contato
                  : null;
              }
            })
        : [];

    const novasMensagens = {};
    contatosFiltrados &&
      contatosFiltrados.forEach((item) => {
        if (!item.chatid || (item.chatid && !mensagensAtuais[item.chatid]))
          return null;
        if (
          dataMsg > item.ultimamsg &&
          !validaTempoMensagem(mensagensAtuais[item.chatid])
        ) {
          return null;
        }
        novasMensagens[item.chatid] = mensagensAtuais[item.chatid];
      });

    if (tab !== 'Todos') setTab('Todos');
    setMsg(novasMensagens);
  }

  function filtro_procura_contato(mensagens, search) {
    const contatosFiltrados =
      contatos &&
      Object.values(contatos).filter((contato) => {
        const searchByName =
          contato.nome &&
          contato.nome !== 'undefined' &&
          typeof contato.nome == 'string'
            ? contato.nome
            : contato.chatid &&
              contato.chatid.split('@') &&
              contato.chatid.split('@')[0];

        const searchByPhone =
          contato.chatid &&
          contato.chatid.split('@') &&
          contato.chatid.split('@')[0];

        return (
          normalizeText(searchByName, 'lower').indexOf(
            normalizeText(search, 'lower')
          ) !== -1 ||
          normalizeText(searchByPhone, 'lower').indexOf(
            normalizeText(search, 'lower')
          ) !== -1
        );
      });

    const novasMensagens = {};
    contatosFiltrados &&
      contatosFiltrados.forEach((contato, index) => {
        if (index <= 100 && contato.chatid && mensagens?.[contato.chatid]) {
          novasMensagens[contato.chatid] = mensagens[contato.chatid];
        }
      });

    return novasMensagens;
  }

  // function filtro_procura_mensagem_fuse(mensagens, search) {
  //   const searchable = Object.values(mensagens)
  //     .map(m => Object.values(m)).flat()
  //     .filter(m => !!m.texto);

  //   const fuse = new window.Fuse(searchable, {
  //     findAllMatches: true,
  //     keys: ['texto'],
  //     includeScore: true,
  //     includeMatches: true
  //   });

  //   const matches = fuse.search(search, { limit: 50 });

  //   return matches
  // }

  function filtro_procura_mensagem(mensagens, search) {
    try {
      const searchable = Object.values(mensagens)
        .map(m => Object.values(m)).flat()
        .filter(m => !!m.texto);
  
      const matches = window.fuzzysort.go(search, searchable, { key:'texto', limit: 20, threshold: -5000 })
        .map(m => ({
          msg: m.obj,
          text: window.fuzzysort.highlight(m, '*', '*'),
        }));
  
      return matches || [];
    } catch { return []; }
  }

  function contatosLoja() {
    function sort_contatos_msg(msgs, ordem = false) {
      let sortable = [];
      for (let index in msgs) {
        if (!msgs[index]) continue;
        sortable.push([index, msgs[index]]);
      }

      if (!sortable) return;

      const newMessages = {};

      sortable
        ?.sort((a, b) => {
          const objA = a[1];
          const objB = b[1];

          const ultima_keyA = Object.keys(objA)[Object.keys(objA).length - 1];
          const ultima_keyB = Object.keys(objB)[Object.keys(objB).length - 1];

          const lastKeyA = objA[ultima_keyA];
          const lastKeyB = objB[ultima_keyB];

          const keyAConversa = lastKeyA?.['conversa'] || '';
          const keyBConversa = lastKeyB?.['conversa'] || '';

          const dataA =
            contatosAtuais?.[keyAConversa]?.['ultimamsg'] ||
            lastKeyA['data_whats'];
          const dataB =
            contatosAtuais?.[keyBConversa]?.['ultimamsg'] ||
            lastKeyB['data_whats'];

          return ordem ? dataB - dataA : dataA - dataB;
        })
        ?.forEach(([key, value]) => {
          newMessages[key] = value;
        });

      return newMessages;
    }

    const unsearchedMensagens = !otimizationCheckbox && search
      ? mensagens : null;
    let mensagensLista = mensagens;

    if (search) {
      iconSearch.current && iconSearch.current.classList.remove('fe-search');
      iconSearch.current && iconSearch.current.classList.add('fe-x');
      mensagensLista = filtro_procura_contato(mensagens, search);
      if (tab !== 'Todos') setTab('Todos');
    } else {
      if (
        iconSearch.current &&
        !iconSearch.current.classList.contains('fe-search')
      ) {
        iconSearch.current.classList.remove('fe-x');
        iconSearch.current.classList.add('fe-search');
      }
    }

    let contatos_nao_lidos = {};
    let contatos_lidos = {};
    let totalContactNumber = 0;

    for (let index in mensagensLista) {
      const listMessages = mensagensLista?.[index] || null;
      if (!listMessages) continue;
      if (!msg && !validaTempoMensagem(listMessages, totalContactNumber))
        continue;

      if (Number(contatosAtuais?.[index]?.['naolidas']) === 0) {
        contatos_lidos[index] = listMessages;
      } else {
        contatos_nao_lidos[index] = listMessages;
      }
      totalContactNumber++;
    }

    contatos_lidos = sort_contatos_msg(contatos_lidos, true);
    contatos_nao_lidos = sort_contatos_msg(contatos_nao_lidos);

    mensagens = { ...contatos_nao_lidos, ...contatos_lidos };

    const otimizationContatosCondition = () => {
      if (otimizationCheckbox) {
        return Object.keys(mensagens).slice(
          contatosPage * paginationQuantity,
          (contatosPage + 1) * paginationQuantity
        );
      } else {
        return Object.keys(mensagens);
      }
    };

    const dados = otimizationContatosCondition()?.map((c) => {
      if (!contatosAtuais || !contatosAtuais?.[c]) return null;
      if (typeof contatosAtuais[c] != 'object') return null;

      const temMensagemNaoLida = Object.entries(contatosAtuais).some(
        (el) => el[1]['naolidas']
      );

      contatosAtuais[c].userchat = String(contatosAtuais[c].userchat);
      userLogado.userchat = String(userLogado.userchat);
      contatosAtuais[c].fechado = String(contatosAtuais[c].fechado);
      contatosAtuais[c].naolidas = String(contatosAtuais[c].naolidas);
      contatosAtuais[c].arquivado = String(contatosAtuais[c].arquivado);

      const lastMessage = mensagens[contatosAtuais[c].chatid]
        ? Object.values(mensagens[contatosAtuais[c].chatid])[
            Object.keys(mensagens[contatosAtuais[c].chatid]).length - 1
          ]
        : '';

      const contatoResult = (
        <Contato
          key={c}
          contato={contatosAtuais[c]}
          lastMessage={lastMessage}
          temMensagemNaoLida={temMensagemNaoLida}
        />
      );

      if (
        contatosAtuais[c].userchat === '0' &&
        contatosAtuais[c].arquivado === '0' &&
        tab === 'Novos' &&
        contatosAtuais[c].ultimamsgtexto
      )
        return contatoResult;

      if (
        userLogado.userchat &&
        contatosAtuais[c].userchat === userLogado.userchat &&
        contatosAtuais[c].fechado === '0' &&
        contatosAtuais[c].arquivado === '0' &&
        tab === 'Meus'
      )
        return contatoResult;

      if (
        contatosAtuais[c].userchat !== '0' &&
        contatosAtuais[c].fechado === '0' &&
        contatosAtuais[c].arquivado === '0' &&
        tab === 'Ativos'
      )
        return contatoResult;

      if (tab === 'Todos') return contatoResult;
      return null;
    });

    if (unsearchedMensagens) {
      const matches = filtro_procura_mensagem(unsearchedMensagens, search);

      const els = matches.map(m => {
        return (<MensagemBusca
          key={'search_' + m.msg.id}
          texto={m.text}
          mensagem={m.msg}
          contato={contatosAtuais[m.msg.conversa]}
        />)
      });

      if (els.length) {
        const msgdiv = (
          <div key='search_msgdiv' className='p-6 font-bold'>
            {t('Mensagens')}
          </div>
        );

        if (dados.length) {
          const chatdiv = (
            <div key='search_chatdiv' className='p-6 font-bold'>
              {t('Conversas')}
            </div>
          );

          dados.unshift(chatdiv);
        }
        
        dados.push(msgdiv, ...els);
      }
    }

    return dados;
  }

  const dictionary = {
    Novos: t('Novos'),
    Meus: t('Meus'),
    Ativos: t('Ativos'),
    Todos: t('Todos'),
  };

  /**
   * marca conversas selecionadas como lidas
   * @param {Array} chatIds
   */
  function marcarConversaLido(chatIds) {
    chatIds.forEach((id) => {
      if (
        !sendSocketDados({
          function: 'enviarMarcarLido',
          data: [
            {
              chatid: id,
              userserv: lojaAtivo,
              userchat: userLogado.userchat,
            },
          ],
        })
      );
      //CONDIÇÃO APENAS PARA ATUALIZAR A VIEW MAIS RÁPIDO (SE NÃO QUISER ESPERAR A RESPOSTA DO WEBSOCKET PODE COLOCAR **PODE FICAR PESADO)
      // setContatos((prev) => {
      //   const updatedContatos = { ...prev };
      //   Object.keys(updatedContatos[lojaAtivo]).forEach((id) => {
      //     updatedContatos[lojaAtivo][id] = {
      //       ...updatedContatos[lojaAtivo][id],
      //       naolidas: '0',
      //     };
      //   });
      //   return updatedContatos;
      // }, true);
    });
  }

  return (
    <>
      <div
        className="modal fade"
        id="printWhatsapp"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex align-items-center flex-fill">
                <div className="icon-shape rounded-lg btn-primary text-white mr-5">
                  <i className="fe-camera" />
                </div>
                <div className="media-body align-self-center">
                  <h5 className="modal-title" style={{ lineHeight: 'initial' }}>
                    Print do Whatsapp
                  </h5>
                  <h6 style={{ lineHeight: 'initial' }}>
                    {lojas ? lojas['nome'] : ''}
                  </h6>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closePrint"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pt-0">
              {printStatus && printStatus['ativo'] ? (
                <>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: require(`../../lotties/${printStatus['icon']}.json`),
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                      },
                    }}
                    height={printStatus['height'] ? printStatus['height'] : 300}
                    width={printStatus['width'] ? printStatus['width'] : 300}
                  />
                  {printStatus['texto'] && (
                    <div className="d-flex align-items-center justify-content-center flex-column mb-4">
                      <strong style={{ fontWeight: '500' }}>
                        {printStatus['texto']}
                      </strong>
                      {printStatus['link'] && (
                        <a
                          href={printStatus['link']}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Clique aqui
                        </a>
                      )}
                    </div>
                  )}
                </>
              ) : null}
              <div id="wppStatus"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="iniciarConversa"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="media flex-fill">
                <div className="icon-shape rounded-lg btn-primary text-white mr-5">
                  <i className="fe-user" />
                </div>
                <div className="media-body align-self-center">
                  <h5 className="modal-title">{t('Cadastrar Contato')}</h5>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closeContato"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleNovaConversa}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="invite-email" className="small">
                        {t('Número')} (whatsapp)
                      </label>
                      <IntlTelInput
                        fieldId="contato-tel"
                        inputClassName="form-control form-control-lg"
                        placeholder={''}
                        format={true}
                        defaultCountry={'br'}
                        onSelectFlag={(data, seletedCountryData) =>
                          setBandeira(seletedCountryData.dialCode)
                        }
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="invite-email" className="small">
                        {t('Mensagem')}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="contato-msg"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-lg btn-block btn-primary d-flex align-items-center"
                >
                  {t('Iniciar Conversa')}
                  <i className="fe-user-plus ml-auto" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="marcadores"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="media flex-fill">
                <div className="icon-shape rounded-lg btn-primary text-white mr-5">
                  <i className="fe-flag" />
                </div>
                <div className="media-body align-self-center">
                  <h5 className="modal-title">{t('Gerenciar marcadores')}</h5>
                  <p className="small">
                    {t('Crie marcadores para filtrar seus clientes')}
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pb-0">
              <form onSubmit={handleSubmitMarcador}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="invite-email" className="small">
                        {t('Titulo')}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="marcador-titulo"
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="invite-email" className="small">
                        {t('Cor')}
                      </label>
                      <input
                        type="color"
                        className="form-control form-control-lg p-3"
                        id="marcador-cor"
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="invite-email" className="small"></label>
                      <button
                        type="submit"
                        className="btn btn-lg btn-block btn-primary d-flex align-items-center justify-content-center rounded"
                      >
                        {t('Criar')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div className="text-left w-100">
                <h5 className="modal-title">{t('Lista de Marcadores')}</h5>
                <div className="pt-3">
                  {marcador[lojaAtivo] &&
                  Object.values(marcador[lojaAtivo]).length ? (
                    Object.values(marcador[lojaAtivo]).map(
                      (item, index) =>
                        item && (
                          <Tag key={index} cor={item.cor && item.cor}>
                            {item.titulo}
                            <i
                              onClick={() => handleRemoveTag(item.id)}
                              className="ml-3 fe-x"
                            />
                          </Tag>
                        )
                    )
                  ) : (
                    <p className="small">
                      Você ainda não criou nenhum marcador. Faça já o seu!
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade order-2"
        id="filtros"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="media flex-fill">
                <div className="icon-shape rounded-lg btn-primary text-white mr-5">
                  <i className="fe-sliders" />
                </div>
                <div className="media-body align-self-center">
                  <h5 className="modal-title">{t('Filtros')}</h5>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label
                    htmlFor="marcador"
                    className="small  d-flex align-items-center"
                  >
                    {t('Filtrar por marcador')}
                    <Tooltip text="Veja o número de contatos com marcadores ou sem marcadores.">
                      <i className="fe fe-help-circle ml-3"></i>
                    </Tooltip>
                  </label>
                  <select
                    name="filter"
                    id="filter"
                    ref={filterRef}
                    className="form-control form-control-lg"
                    defaultValue={'inicial'}
                  >
                    {listaMarcadores()}
                  </select>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="marcador"
                    className="small d-flex align-items-center"
                  >
                    {t('Filtrar por atendente')}
                    <Tooltip text="Veja o número de contatos que cada atendente possui!">
                      <i className="fe fe-help-circle ml-3"></i>
                    </Tooltip>
                  </label>
                  <select
                    name="atendentes"
                    ref={atendentesRef}
                    className="form-control form-control-lg"
                    defaultValue={'inicial'}
                  >
                    {listaContatosAtendentes()}
                  </select>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="marcador"
                    className="small d-flex align-items-center"
                  >
                    {t('Filtrar por Data')}
                    <Tooltip text="Mostre apenas os contatos façam sentido para você!">
                      <i className="fe fe-help-circle ml-3"></i>
                    </Tooltip>
                  </label>
                  <select
                    name="dataMensagens"
                    ref={dataMensagensRef}
                    className="form-control form-control-lg"
                    defaultValue={defaultFilterDate}
                    onInput={updateFilterDate}
                  >
                    {listDates &&
                      Object.entries(listDates)?.map(([text, value], index) => (
                        <option key={index} data-value={text} value={value}>
                          {t(text)}
                        </option>
                      ))}
                  </select>
                </div>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-lg btn-block btn-primary d-flex align-items-center"
                  onClick={handleFilter}
                >
                  {t('Filtrar')}
                  <i className="fe-filter ml-auto" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="notificacoes"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex align-items-center flex-fill">
                <div className="icon-shape rounded-lg btn-primary text-white mr-5">
                  <i className="fe fe-bell" />
                </div>
                <div className="media-body align-self-center">
                  <h5 className="modal-title" style={{ lineHeight: 'initial' }}>
                    {t('Sons de notificação')}
                  </h5>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  marginTop: '16px',
                }}
              >
                <input
                  style={{ filter: 'hue-rotate(300deg)' }}
                  type="checkbox"
                  checked={whatsappRingTune}
                  onChange={(e) => {
                    setWhatsappRingTune(e.target.checked);
                    localStorage.setItem(
                      'checkboxOnReceiveMessageSound',
                      e.target.checked
                    );
                  }}
                />
                <label style={{ marginLeft: '8px', marginBottom: '0' }}>
                  {t('Reproduzir sons ao receber mensagens')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="otimizacao"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex align-items-center flex-fill">
                <div className="icon-shape rounded-lg btn-primary text-white mr-5">
                  <i className="fe fe-bell" />
                </div>
                <div className="media-body align-self-center">
                  <h5 className="modal-title" style={{ lineHeight: 'initial' }}>
                    Otimização
                  </h5>
                </div>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  marginTop: '16px',
                }}
              >
                <input
                  style={{ filter: 'hue-rotate(300deg)' }}
                  type="checkbox"
                  checked={otimizationCheckbox}
                  onChange={(e) => {
                    setOtimizationCheckbox(e.target.checked);
                    localStorage.setItem(
                      'otimizationContatos',
                      e.target.checked
                    );
                  }}
                />
                <label style={{ marginLeft: '8px', marginBottom: '0' }}>
                  {t('Ativar paginação de contatos')}
                </label>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                  marginTop: '16px',
                }}
              >
                <input
                  style={{ filter: 'hue-rotate(300deg)' }}
                  type="checkbox"
                  checked={otimizationLoadOnlyContatosComNaolidasCheckbox}
                  onChange={(e) => {
                    setOtimizationLoadOnlyContatosComNaolidasCheckbox(
                      e.target.checked
                    );
                    localStorage.setItem(
                      'otimizationLoadOnlyContatosComNaolidasCheckbox',
                      e.target.checked
                    );
                  }}
                />
                <label style={{ marginLeft: '8px', marginBottom: '0' }}>
                  {t('Carregar apenas contatos com mensagens não lidas')}{' '}
                  <b style={{ color: 'red' }}>
                    **{t('Necessário atualizar a página')}**
                  </b>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between mr-7 ml-6">
        <ul
          className="order-0 nav nav-tabs bg-transparent w-100 p-0"
          id="myTab"
          role="tablist"
        >
          {Object.entries(notifTab)?.map(([texto, count], index) => (
            <Tabs key={index} className="nav-item" notif={count}>
              <Tooltip
                text={texto === 'Ativos' ? t('Contatos em Atendimento') : ''}
              >
                <a
                  className={
                    'nav-link bg-transparent ' +
                    (tab === texto ? 'active ' : '')
                  }
                  id={`${texto}-tab`}
                  data-toggle="tab"
                  onClick={() => setTab(texto)}
                  href={`#${texto}`}
                  role="tab"
                  aria-controls={texto}
                  aria-selected="true"
                >
                  {dictionary[texto]}
                </a>
              </Tooltip>
            </Tabs>
          ))}
          <li
            className="nav-item list-inline-item d-flex align-items-center justify-content-end"
            style={{ flex: 1 }}
          >
            <a
              className="nav-link text-muted px-0 mr-4"
              href="# "
              data-toggle="modal"
              data-target="#filtros"
            >
              <Tooltip text="Filtros">
                <i className="icon-md fe-sliders" />
              </Tooltip>
            </a>
            <div className="dropdown">
              <a
                className="nav-link text-muted px-0"
                href="# "
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <Tooltip text="Configurações">
                  <i className="icon-md fe-settings" />
                </Tooltip>
              </a>
              <div className="dropdown-menu">
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="# "
                  data-toggle="modal"
                  data-target="#iniciarConversa"
                >
                  {t('Novo Contato')}
                  <span className="ml-auto pl-3 fe-user-plus" />
                </a>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="# "
                  onClick={printWpp}
                >
                  {t('Ver WhatsappWeb')}
                  <span className="ml-auto pl-3 fe-camera" />
                </a>
                <a
                  href="# "
                  id="wppPrint"
                  data-toggle="modal"
                  data-target="#printWhatsapp"
                  style={{ display: 'none' }}
                >
                  _
                </a>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="# "
                  data-toggle="modal"
                  data-target="#marcadores"
                >
                  {t('Gerenciar marcadores')}
                  <span className="ml-auto pl-3 fe-flag" />
                </a>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="# "
                  data-toggle="modal"
                  data-target="#notificacoes"
                >
                  {t('Notificações')}
                  <span className="ml-auto pl-3 fe-bell" />
                </a>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="# "
                  data-toggle="modal"
                  data-target="#otimizacao"
                >
                  {t('Otimização')}
                  <span className="ml-auto pl-3 fe-activity" />
                </a>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="# "
                  onClick={() => {
                    setToggleMarcarLidoBoxAndCheckbox(
                      !toggleMarcarLidoBoxAndCheckbox
                    );
                  }}
                >
                  {t('Selecionar conversas')}
                  <span className="ml-auto pl-3 fe-target" />
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="input-group">
        <input
          type="text"
          ref={inputSearch}
          className="form-control form-control-lg rounded-0"
          placeholder={t('Buscar contato por nome ou tel')}
          aria-label="Procurar"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="input-group-append" style={{ zIndex: 0 }}>
          <button
            className="btn btn-lg btn-ico btn-secondary btn-minimal rounded-0"
            type="button"
            onClick={clearSearch}
          >
            <i ref={iconSearch} className="fe-search" />
          </button>
        </div>
      </div>

      {toggleMarcarLidoBoxAndCheckbox ? (
        <section
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '#f3f4f9',
            padding: '8px',
            flexWrap: 'wrap',
            gap: '.5rem',
          }}
        >
          <div className="d-flex align-items-center">
            <button
              style={{
                background: 'none',
                outline: 'none',
                border: 'none',
                fontSize: '20px',
              }}
              onClick={() => setToggleMarcarLidoBoxAndCheckbox(false)}
            >
              <i className="fe fe-x" />
            </button>
            <span>Selecionada: {checkboxMarcadosComoLidoQuantity}</span>
          </div>
          {showMarcarLidoBtn ? (
            <div class="dropdown">
              <a
                className="nav-link text-muted px-0"
                href="# "
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  class="fe fe-more-horizontal"
                  style={{
                    fontSize: '20px',
                    color: '#212529',
                    padding: '0 8px',
                  }}
                ></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href=" #">
                  <button
                    style={{
                      background: 'none',
                      outline: 'none',
                      border: 'none',
                      color: '#7f808c',
                    }}
                    onClick={() => {
                      const values = Array.from(
                        document.querySelectorAll(
                          '[name="checkbox_marcar_lido"]:checked'
                        )
                      ).map((el) => el.value);
                      marcarConversaLido(values);
                    }}
                  >
                    Marcar selecionados como lido
                  </button>
                </a>
              </div>
            </div>
          ) : null}
        </section>
      ) : null}

      <Errors />
      <div className="tab-scroll" ref={containerListaContatos}>
        {!search && <MensagensAntigas />}
        <ErrorChat codeError={777}>{contatosLoja()}</ErrorChat>
        {otimizationCheckbox && (
          <ContatosPagination
            contatosPage={contatosPage}
            setContatosPage={setContatosPage}
            mensagens={mensagens}
            paginationQuantity={paginationQuantity}
          />
        )}

        {mensagens && Object.values(mensagens)?.length < 15 && (
          <button
            type="button"
            data-toggle="modal"
            data-target="#iniciarConversa"
            className="sak--novo-contato"
          >
            {t('Não encontrou o que procura? Crie um novo contato')}
            <strong> {t('clicando aqui')}</strong>.
          </button>
        )}
      </div>
    </>
  );
}

export default memo(Aside);
