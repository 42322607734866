import { create } from 'zustand';

const useDadosSakStore = create((set) => ({
  contatos: {},
  setContatosStore: (callback, shouldSpread) =>
    set((state) => ({
      contatos: shouldSpread ? callback(state.contatos) : callback(),
    })),
  onError: [],
  setOnErrorStore: (callback, shouldSpread) =>
    set((state) => ({
      onError: shouldSpread ? callback(state.onError) : callback(),
    })),
  contatoAtivo: {},
  setContatoAtivoStore: (callback, shouldSpread) =>
    set((state) => ({
      contatoAtivo: shouldSpread ? callback(state.contatoAtivo) : callback(),
    })),
  ultimoContato: 0,
  setUltimoContatoStore: (callback, shouldSpread) =>
    set((state) => ({
      ultimoContato: shouldSpread ? callback(state.ultimoContato) : callback(),
    })),
  mensagens: {},
  setMensagensStore: (callback, shouldSpread) =>
    set((state) => ({
      mensagens: shouldSpread ? callback(state.mensagens) : callback(),
    })),
  userLogado: {},
  setUserLogadoStore: (callback, shouldSpread) =>
    set((state) => ({
      userLogado: shouldSpread ? callback(state.userLogado) : callback(),
    })),
  userChat: {},
  setUserChatStore: (callback, shouldSpread) =>
    set((state) => ({
      userChat: shouldSpread ? callback(state.userChat) : callback(),
    })),
  lojas: {},
  setLojasStore: (callback, shouldSpread) =>
    set((state) => ({
      lojas: shouldSpread ? callback(state.lojas) : callback(),
    })),
  lojaAtivo: 0,
  setLojaAtivoStore: (callback, shouldSpread) =>
    set((state) => ({
      lojaAtivo: shouldSpread ? callback(state.lojaAtivo) : callback(),
    })),
  statusApp: 1,
  setStatusAppStore: (callback, shouldSpread) =>
    set((state) => ({
      statusApp: shouldSpread ? callback(state.statusApp) : callback(),
    })),
  marcadores: {},
  setMarcadoresStore: (callback, shouldSpread) =>
    set((state) => ({
      marcadores: shouldSpread ? callback(state.marcadores) : callback(),
    })),
  lastMsg: {},
  setLastMsgStore: (callback, shouldSpread) =>
    set((state) => ({
      lastMsg: shouldSpread ? callback(state.lastMsg) : callback(),
    })),
  statusSocket: true,
  setStatusSocketStore: (callback, shouldSpread) =>
    set((state) => ({
      statusSocket: shouldSpread ? callback(state.statusSocket) : callback(),
    })),
  printStatus: {},
  setPrintStatusStore: (callback, shouldSpread) =>
    set((state) => ({
      printStatus: shouldSpread ? callback(state.printStatus) : callback(),
    })),
  globalLoading: {
    percent: 100,
    step: '',
  },
  setGlobalLoadingStore: (callback, shouldSpread) =>
    set((state) => ({
      globalLoading: shouldSpread ? callback(state.globalLoading) : callback(),
    })),
  printWpp: (e) => {
    e.preventDefault();
    const id =
      useDadosSakStore.getState().lojas?.[
        useDadosSakStore.getState().lojaAtivo
      ]?.['user'];
    if (!id) return null;

    const wppButton = document.querySelector('#wppPrint');
    const wppStatus = document.querySelector('#wppStatus');
    useDadosSakStore.setState(() => ({
      printStatus: {
        ativo: true,
        icon: 'loadmessages',
        width: 200,
        height: 200,
        texto: 'Carregando...',
      },
    }));

    wppStatus.innerHTML = '';
    wppButton.click();

    window.submitdado(
      { id },
      `${window.SakChatUrl}/Ajax/screenshotWhatsApp`,
      function (retorno) {
        if (retorno === 'error' || typeof retorno === 'undefined') {
          useDadosSakStore.setState(() => ({
            ativo: true,
            icon: 'wppDesconectado',
            texto: 'Whatsapp Desconectado',
            link: 'https://app.sak.com.br/whatsapps',
          }));
        } else {
          useDadosSakStore.setState({ ativo: () => false });

          wppStatus.innerHTML = retorno;
        }
      }
    );
  },
}));

export default useDadosSakStore;
