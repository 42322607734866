import { create } from 'zustand';

const useImageExpand = create((set) => ({
  imageExpand: '',
  setImageExpandStore: (callback, shouldSpread) =>
    set((state) => ({
      imageExpand: shouldSpread ? callback(state.imageExpand) : callback(),
    })),
}));

export default useImageExpand;
