export function throttle(func, wait) {
  let lastCallTime = 0;
  return function (...args) {
    const now = Date.now();
    if (now - lastCallTime >= wait) {
      func.apply(this, args);
      lastCallTime = now;
    }
  };
}

export const getToken = () =>
  JSON.parse(localStorage.getItem('inicializacao'))['token'];
