import React, { createContext, useContext, useState } from 'react';

const RespostaContext = createContext();

export default function RespostaProvider({ children }) {
  const [idMessage, setIdMessage] = useState([]);

  return (
    <RespostaContext.Provider value={{ idMessage, setIdMessage }}>
      {children}
    </RespostaContext.Provider>
  );
}

export function useResposta() {
  const context = useContext(RespostaContext);
  const { idMessage, setIdMessage } = context;

  return { idMessage, setIdMessage };
}
