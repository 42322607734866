import styled from "styled-components";
import { shade } from "polished";

export const Tags = styled.div`
  font-size: 12px;
  width: ${(props) => (props.select ? "100%" : "auto")};
  background: ${(props) => (props.cor ? props.cor : "var(--basic-inverse)")};
  color: ${(props) => (props.cor ? "#fff" : "var(--basic)")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.small ? "4px 8px" : "5px 8px")};
  margin-left: ${(props) => (props.small ? "5px" : "0")};
  margin-top: ${(props) => (props.small ? "0" : "5px")};
  margin-bottom: ${(props) => (props.select ? "0" : "5px")};
  margin-right: 5px;
  border-radius: ${(props) => (props.select ? "5px" : "25px")};
  cursor: ${(props) => (props.select ? "pointer" : "auto")};
  transition: background 0.2s;

  i {
    cursor: pointer;
    background: ${(props) => (props.cor ? shade(0.2, props.cor) : "black")};
    padding: 2px;
    border-radius: 50%;
  }

  &:hover {
    background: ${(props) => (props.cor ? shade(0.1, props.cor) : "#5d5d5d85")};
  }
`;
