import styled from 'styled-components';

export const Tabs = styled.li`
  position: relative;
  padding-top: 0.7rem;

  &:after {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff5848;
    width: 18px;
    height: 18px;
    z-index: 0;

    position: absolute;
    top: -5px;
    right: -8px;
    font-size: 0.7rem;

    border-radius: 12px;
    border: 1px solid var(--light);

    pointer-events: none;
    font-weight: bold;
    color: white;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    content: '${(props) => props.notif && props.notif}';
    display: ${(props) => (props.notif && props.notif > 0 ? 'flex' : 'none')};
  }

  a {
    padding: 0 0.45rem 0.7rem;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid transparent !important;
  }

  .active {
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid var(--basic-inverse) !important;
  }

  @media (max-width: 900px) {
    a {
      padding: 0px 1.4rem 0.7rem;
      font-size: 1rem;
    }
  }

  @media (max-width: 562px) {
    a {
      padding: 0px 0.8rem 0.7rem;
    }
  }
`;
