import React, { useState, useEffect, useRef } from 'react';

import {
  ListAtends,
  EncerrarConversa,
  AtualAtendente,
  Assumir,
  Tabs,
  Anotacao,
  TextArea,
  AvatarEdit,
  Marcador,
} from './styles';

import Conversa from '../Conversa';

import 'emoji-mart/css/emoji-mart.css';
import listaEmojis from 'emoji-mart/data/twitter.json';
import { NimblePicker } from 'emoji-mart';

import Tag from '../Tags';

import { ErrorChat } from '../../components/errors';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { restringirNome, formatPhoneNumber } from '../../utils/formatDados';

import Avatar from '../../components/avatar';
import Tooltip from '../Tooltip';

import { gamification } from '../../utils/gamification';

import { useResposta } from '../../hooks/responderMsgContext';
// import RespostasRapidas from '../../hooks/rapidaMsgContext';
import { useMensagemRapida } from '../../hooks/rapidaMsgContext';
import DelayMessage from './components/DelayMessage';
import RecordAudio from './components/RecordAudio';
import useDadosSakStore from '../../stores/useDadosSakStore';
import useSocketStore from '../../stores/useSocketStore';
import ImageRepository from './components/ImageRepository/index';
import { useTranslation } from 'react-i18next';

const translateEmojis = {
  search: 'Pesquisar emoji',
  clear: 'Limpar',
  notfound: 'Emoji não encontrado!',
  categories: {
    search: 'Resultados',
    recent: 'Recentes',
    people: 'Smileys & Pessoas',
    nature: 'Animais & Natureza',
    foods: 'Comidas e Bebidas',
    activity: 'Atividades',
    places: 'Viagens e Lugares',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    custom: 'Custom',
  },
};

const CaixaMensagem = ({
  contato,
  userchat,
  mensagens,
  inicial,
  marcadores,
  lastMessage,
  lojas,
}) => {
  const [tab, setTab] = useState('Publica');
  // const { sendSocketDados } = useSocket();
  const { t, i18n } = useTranslation();
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const delayMessage = useSocketStore((state) => state.delayMessage);

  const textAreaSendMessage = useRef([]);
  const { idMessage, setIdMessage } = useResposta();
  const { setTextMessage } = useMensagemRapida();
  // const { lojaAtivo } = useLojaAtiva();
  // const { userLogado } = useUserLogado();
  // const { contatoAtivo, setContatoAtivo } = useContatoAtivo();
  const [attFotoTime, setAttFotoTime] = useState(0);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showDelayMessage, setShowDelayMessage] = useState(false);
  const [showRepositoryBox, setshowRepositoryBox] = useState(false);
  const inputTextRef = useRef(null);
  const emojiRef = useRef(null);
  const emojiBtn = useRef(null);

  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);

  useEffect(() => {
    if (!showEmojis) return;

    const handleCloseEmoji = (event) =>
      event.keyCode === 27 && setShowEmojis(false);

    document.addEventListener('keyup', handleCloseEmoji);

    return () => {
      document.removeEventListener('keyup', handleCloseEmoji);
    };
  }, [showEmojis]);

  useEffect(() => {
    typeof window.inicializarFuncaoTemplate === 'function' &&
      window.inicializarFuncaoTemplate(window.jQuery);

    typeof window.submitdado === 'function' &&
      window.submitdado({}, `${window.SakChatUrl}/Js/loadBoxChatSak`, () => {
        window.submitdado({}, `${window.SakChatUrl}/Js/getShortcut`);
      });
    const findExtensao = document.querySelector('#chat-1-info');

    findExtensao &&
      window.innerWidth < 1200 &&
      findExtensao.classList.contains('chat-sidebar-visible') &&
      findExtensao.classList.remove('chat-sidebar-visible');
  }, [lojaAtivo]);

  useEffect(() => {
    const handleTextAreaSeparatedMessages = () => {
      const textAreaInput = document.getElementById('chat-id-1-input');

      if (textAreaInput) {
        textAreaInput.value =
          textAreaSendMessage.current[`Conversa-${contatoAtivo.chatid}`] || '';
      }
    };
    handleTextAreaSeparatedMessages();

    if (!contatoAtivo.texto) return;
    const inputState = inputTextRef ? inputTextRef.current : '';
    if (!inputState) return;
    inputState.value = contatoAtivo.texto;
    delete contatoAtivo.texto;
    setContatoAtivo(() => contatoAtivo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contatoAtivo]);

  useEffect(() => {
    let numeroContato = '+' + contatoAtivo.chatid.split('@')[0].toString();
    let cancelar = {};
    if (typeof window.submitdado === 'function') {
      cancelar = window.submitdado(
        { q: formatPhoneNumber(numeroContato) },
        `${window.SakChatUrl}/Js/SFBuscaInfo/navapps`
      );
    }
    return () => {
      if (typeof cancelar === 'function') {
        cancelar();
      }
    };
  }, [contatoAtivo]);

  const handleTextSend = (event) => {
    const keyCode = event.which || event.keyCode;

    if (keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleSubmitMensagem(event);
    }
  };

  const handleTextChange = (event) => {
    const msgText = event.target.value;
    textAreaSendMessage.current[event.target.name] = event.target.value;
    const arrayMsgs = window.SAKshortcut && Object.entries(window.SAKshortcut);
    const containerInput = inputTextRef?.current || null;

    setTextMessage(String(msgText));

    if (!arrayMsgs) return;

    arrayMsgs.some((item) => {
      if (
        msgText.includes(`/${item[0]}`) &&
        !msgText.split(`/${item[0]}`)[
          Object.keys(msgText.split(`/${item[0]}`)).length - 1
        ]
      ) {
        event.target.value = event.target.value.replace(`/${item[0]}`, item[1]);
        textAreaSendMessage.current[event.target.name] = event.target.value;
        setTextMessage(String(event.target.value));
        return event.target.value;
      }
      return '';
    });

    if (!containerInput) return;

    /* Redimensionamento do textarea */
    const sizeLength = 7; // quantidade px de cada letra
    const textLength = containerInput?.value?.length || 0; // quantidade de carater
    const baseWidth = 141; // é o tamanho mínimo do bloco de texto
    const lengthWidth = baseWidth + textLength * sizeLength; // tamanho do container com caracteres
    const containerWidth = containerInput.offsetWidth;
    const calcLines = ~~(lengthWidth / containerWidth);

    const numeroDeLinhas = (containerInput.value.match(/\n/g) || [])?.length;
    const defaultHeight = 30 + (calcLines + numeroDeLinhas) * 18 + 12 + 2;
    containerInput.style.height = `${defaultHeight}px`;
  };

  const telNumber = () => {
    const isGroup = contatoAtivo['chatid'].split('@')[1] === 'g.us';
    if (isGroup) {
      return 'Grupo';
    } else {
      let numeroContato = '+' + contatoAtivo.chatid.split('@')[0].toString();
      return formatPhoneNumber(numeroContato);
    }
  };

  const copyPhone = () => {
    const dados = contatoAtivo.chatid.split('@')[0].toString();

    if (typeof navigator.clipboard == 'undefined') {
      const textArea = document.createElement('textarea');
      textArea.value = formatPhoneNumber(dados);
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      window.notifChat('Número de telefone copiado!', 'success');
      document.body.removeChild(textArea);
    } else {
      navigator.clipboard
        .writeText(formatPhoneNumber(dados))
        .then(() => window.notifChat('Número de telefone copiado!', 'success'))
        .catch((_) => window.notifChat('Algo deu errado!', 'warning'));
    }
  };

  function handleAddTag(id) {
    if (
      !sendSocketDados({
        function: 'enviarMarcadorContato',
        data: [
          {
            id,
            userserv: contatoAtivo.userserv,
            userchat: userLogado.userchat,
            chatid: contatoAtivo.chatid,
          },
        ],
      })
    )
      return null;

    gamification('enviarMarcadorContato');
    setContatoAtivo((prev) => {
      if (!prev['marcadores']) {
        prev['marcadores'] = [id];
        return { ...prev };
      }
      prev['marcadores'] = [...prev['marcadores'], id];
      return { ...prev };
    }, true);
  }

  function atualizarFoto() {
    const atualTimeStamp = ~~(Date.now() / 1000);
    if (attFotoTime) {
      const tempoRestante = ~~(attFotoTime - atualTimeStamp);
      window.notifChat(
        `Você só pode atualizar a foto de perfil novamente em ${tempoRestante} segundos!`,
        'warning'
      );
      return;
    }

    const oneMinute = 1000 * 60;
    const atualTimeStampPosOneMinute =
      new Date(Date.now() + oneMinute).getTime() / 1000;
    setAttFotoTime(atualTimeStampPosOneMinute);
    console.log('contato atualizado');
    if (
      !sendSocketDados({
        function: 'atualizarFotos',
        data: [
          {
            chatid: contatoAtivo.chatid,
            userserv: contatoAtivo.userserv,
          },
        ],
      })
    )
      return null;
    setTimeout(() => setAttFotoTime(0), oneMinute);
  }

  function handleRemoveTag(id) {
    if (
      !sendSocketDados({
        function: 'removerMarcadorContato',
        data: [
          {
            id,
            userserv: contatoAtivo.userserv,
            userchat: userLogado.userchat,
            chatid: contatoAtivo.chatid,
          },
        ],
      })
    )
      return null;

    setContatoAtivo((prev) => {
      const findNovosMarcadores =
        prev['marcadores'] &&
        Object.values(prev['marcadores']).filter(
          (marcador) => String(marcador) !== String(id)
        );
      prev['marcadores'] = findNovosMarcadores;
      return { ...prev };
    }, true);

    gamification('removerMarcadorContato');
  }

  function handleSubmitMensagem(event) {
    event.preventDefault();
    const inputState = inputTextRef ? inputTextRef.current : '';

    if (
      !inputState.value.trim() &&
      window.cacheImgDrop &&
      Object.values(window.cacheImgDrop).length === 0
    ) {
      return null;
    }

    const contato = contatoAtivo.chatid;
    let dadosStorage = mensagens;
    for (let msgIndex in dadosStorage[contato]) {
      if (!dadosStorage?.[contato]?.[msgIndex]) continue;
      if (typeof dadosStorage?.[contato]?.[msgIndex] != 'object') continue;
      dadosStorage[contato][msgIndex].lida = 1;
    }

    const filterAnexosList = window.cacheImgDrop
      ? Object.values(window.cacheImgDrop)?.filter(
          (v, i, a) => a.findIndex((t) => t.uuid === v.uuid) === i
        ) || []
      : [];

    const findAnexosIfStatusOk = filterAnexosList.every(
      (img) => img.status === 'ok'
    );

    if (!findAnexosIfStatusOk) {
      window.notifChat('Aguarde o envio das imagens', 'error');
      return;
    }

    window.cacheImgDrop = {};

    const allCacheImages = document.querySelectorAll('[data-dz-remove=true]');
    allCacheImages &&
      Object.values(allCacheImages).forEach((img) => img.click());

    let resposta = '';

    const tipoMensagem = {
      Publica: 'enviarMensagem',
      Privada: 'enviarMensagemInterna',
    };

    if (idMessage.length) {
      const findResposta = idMessage.find(
        (item) => item.conversaID === contatoAtivo.chatid
      );
      if (findResposta) {
        resposta = findResposta.id;
        if (tipoMensagem[tab] === 'enviarMensagem') {
          idMessage.splice(findResposta);
          setIdMessage([...idMessage]);
        } else {
          window.notifChat(
            'Você não pode responder com uma mensagem privada!',
            'warning'
          );
        }
      }
    }

    if (tab === 'Publica') gamification('EnviarNovaMensagem');
    else gamification('enviarMensagemInterna');

    const idProvisorio = crypto.randomUUID();
    // const atualTimeStamp = Date.now() / 1000;

    // setMensagens((prevMsg) => {
    //   if (!prevMsg[lojaAtivo][contato]) return { ...prevMsg };

    //   const dadosMSG = {
    //     Nfrom: `${lojas.whatsapp}@c.us`,
    //     conversa: contato,
    //     data_whats: atualTimeStamp,
    //     id: idProvisorio,
    //     inclusao: atualTimeStamp,
    //     privado: 0,
    //     me: '1',
    //     status: '-2',
    //     texto: inputState.value.trim() || '',
    //     userchat: userLogado.userchat,
    //     userserv: lojaAtivo,
    //   };

    //   const possuiAnexos = Object.values(filterAnexosList).length;
    //   console.log(filterAnexosList)

    //   if(!possuiAnexos) {
    //     prevMsg[lojaAtivo][contato][idProvisorio] = dadosMSG;
    //   }else {
    //     filterAnexosList.forEach(anexo => {
    //       const { idProvisorio: id, url } = anexo || {};
    //       console.log(anexo['uuid'])
    //       dadosMSG['anexo'] = url;
    //       dadosMSG['id'] = id;
    //       prevMsg[lojaAtivo][contato][id] = dadosMSG;
    //     })
    //   }

    //   const lastMessage = {};
    //   lastMessage[contato] = dadosMSG;

    //   setLastMsg(lastMessage);

    //   setContatos((contact) => {
    //     if (!contact?.[lojaAtivo]?.[contato]) return { ...contact };

    //     contact[lojaAtivo][contato] = Object.assign(
    //       contact[lojaAtivo][contato],
    //       {
    //         ultimamsg: atualTimeStamp,
    //         ultimamsgtexto: possuiAnexos ? 'Anexo' : dadosMSG['texto'],
    //         naolidas: 0,
    //       }
    //     );

    //     return { ...contact };
    //   });

    //   return { ...prevMsg };
    // });

    const adiar = () => {
      const isThereActive = document.querySelector(
        '.delay-message-badge.active'
      );

      if (isThereActive && tipoMensagem[tab] === 'enviarMensagemInterna') {
        return { adiar: delayMessage };
      } else {
        return {};
      }
    };

    if (
      !sendSocketDados({
        function: tipoMensagem[tab],
        data: [
          {
            idProvisorio,
            texto: inputState.value.trim(),
            conversa: contato,
            userserv: lojaAtivo,
            anexo: filterAnexosList,
            userchat: userLogado.userchat,
            resposta,
            ...adiar(),
          },
        ],
      })
    )
      return null;

    if (
      contatoAtivo &&
      String(contatoAtivo.userchat) === '0' &&
      tab === 'Publica'
    ) {
      if (
        !sendSocketDados({
          function: 'assumirConversa',
          data: [
            {
              id: contatoAtivo.chatid,
              userserv: lojaAtivo,
              userchat: userLogado.userchat,
            },
          ],
        })
      )
        return null;

      contatoAtivo.userchat = userLogado.userchat;

      setContatoAtivo(() => ({
        ...contatoAtivo,
        ...{
          userchat: userLogado.userchat,
        },
      }));
    }

    inputState.value = '';
    textAreaSendMessage.current[`Conversa-${contatoAtivo.chatid}`] = '';

    inputState.focus();
    inputState.setAttribute('style', '');
    setShowEmojis(false);
  }

  function assumirConversa() {
    if (
      !sendSocketDados({
        function: 'assumirConversa',
        data: [
          {
            id: contatoAtivo.chatid,
            userserv: lojaAtivo,
            userchat: userLogado.userchat,
          },
        ],
      })
    )
      return null;

    setContatoAtivo(() => ({
      ...contatoAtivo,
      ...{
        userchat: userLogado.userchat,
      },
    }));
  }

  async function ArquivarContato() {
    const nomeDoContato = restringirNome(
      contatoAtivo.nome,
      19,
      true,
      contatoAtivo.chatid
    );

    const arquivar = () => {
      if (
        !sendSocketDados({
          function: 'arquivarContato',
          data: [
            {
              id: contatoAtivo.chatid,
              userserv: lojaAtivo,
              userchat: userLogado.userchat,
            },
          ],
        })
      )
        return null;

      window.notifChat(`${nomeDoContato} foi arquivado com sucesso!`);
    };

    gamification('encerrarContato');

    const dadosFetch = {
      method: 'POST',
      body: JSON.stringify({
        user_pai_id: userchat[userLogado.userchat].user_pai_id,
        phone_loja: lojas.whatsapp,
        phone_user: contatoAtivo.chatid.split('@')[0],
        chave_empreender: lojas['chave_empreender'],
        version: 2,
      }),
    };

    const teenDays = 1000 * 60 * 60 * 24 * 10;

    // Só avalia se for menor que 10 dias
    if (
      !Number(contatoAtivo?.userchat) ||
      contatoAtivo?.ultimamsg <= teenDays
    ) {
      return arquivar();
    }

    fetch(process.env.REACT_APP_AVALIACOES_LINK, dadosFetch)
      .then((r) => {
        if (r.ok) {
          r.json().then((dados) => {
            const texto = dados['mensagem']
              ? dados['mensagem']
              : `*#Robô:* Atendimento encerrado. Clique no *link* para avaliar nosso atendimento ${dados['url']}`;

            if (
              !sendSocketDados({
                function: 'enviarMensagem',
                data: [
                  {
                    texto,
                    conversa: contatoAtivo.chatid,
                    userserv: lojaAtivo,
                    anexo: [],
                    userchat: userLogado.userchat,
                    resposta: 0,
                    avaliacao: true,
                  },
                ],
              })
            )
              return null;
            arquivar();
          });
        } else {
          arquivar();
        }
      })
      .catch((_) => arquivar());
    setContatoAtivo(() => '');
  }

  function fecharChat() {
    const chat = document.getElementById('main-chat');
    chat.classList.remove('main-visible');
  }

  function TrocarAtendente(atendente) {
    if (
      !sendSocketDados({
        function: 'trocarAtendente',
        data: [
          {
            conversa: contatoAtivo.chatid,
            userchat_acao: userLogado.userchat,
            userchat: atendente,
            userserv: lojaAtivo,
          },
        ],
      })
    )
      return null;

    gamification('trocarAtendente');
    setContatoAtivo(() => ({
      ...contatoAtivo,
      ...{
        userchat: atendente,
      },
    }));
  }

  function RemoverAtendente(atendente) {
    if (
      !sendSocketDados({
        function: 'removerAtendente',
        data: [
          {
            conversa: contatoAtivo.chatid,
            userchat: atendente,
            userserv: lojaAtivo,
          },
        ],
      })
    )
      return null;

    gamification('removerAtendente');
    setContatoAtivo(() => ({
      ...contatoAtivo,
      ...{
        userchat: 0,
      },
    }));
  }

  function listarAtendentes() {
    const Loja = lojas;
    contatoAtivo.arquivado = String(contatoAtivo.arquivado);

    return (
      <>
        {userchat &&
          Loja.userchat &&
          Loja.userchat.map((atendente) => {
            if (!atendente || !userchat[atendente]) return null;
            if (userchat[atendente]['excluido'] !== '0') return null;
            return (
              <ListAtends
                key={atendente}
                className="dropdown-item d-flex align-items-center"
                href="# "
                onClick={() => TrocarAtendente(userchat[atendente].userchat)}
                color={userchat[atendente].cor}
              >
                {userchat[atendente].nome}
              </ListAtends>
            );
          })}
        {contatoAtivo.userchat ? (
          <ListAtends
            onClick={() => RemoverAtendente(contatoAtivo.userchat)}
            color={'#868e9a'}
            className="dropdown-item d-flex align-items-center"
            href="# "
          >
            {t('Remover Atendente')}
          </ListAtends>
        ) : (
          ''
        )}
        {/* {contatoAtivo.arquivado === '0' && (
          <ListAtends
            onClick={() => ArquivarContato()}
            color={'#FF5848'}
            className="dropdown-item d-flex align-items-center"
            href="# "
          >
            Encerrar Conversa
          </ListAtends>
        )} */}
      </>
    );
  }
  return (
    <>
      <div className="d-flex flex-column h-100">
        <div
          id="chat-1"
          className="chat dropzone-form-js"
          data-dz-url="https://chat.sak.com.br/upload.php"
        >
          <div className="chat-body">
            <div className="chat-header border-bottom py-4 py-lg-6 px-lg-8">
              <div className="container-fluid">
                <div className="row align-items-center d-flex justify-content-between">
                  <div className="pl-6 d-xl-none d-flex align-items-center">
                    <ul className="list-inline mb-0 mr-5">
                      <li className="list-inline-item">
                        <a
                          className="text-muted px-0"
                          href="# "
                          onClick={() => fecharChat()}
                        >
                          <i className="icon-md fe-chevron-left" />
                        </a>
                      </li>
                    </ul>
                    <div className="d-flex d-xl-none" style={{ flex: 1 }}>
                      <div className="media text-left text-xl-left d-flex align-items-center">
                        <div className="avatar avatar-sm d-xl-inline-block mr-5">
                          <Avatar dados={contatoAtivo} />
                        </div>
                        <div className="media-body align-self-center text-truncate">
                          <h6 className="text-truncate text-left mb-n1">
                            {restringirNome(
                              contatoAtivo.nome,
                              19,
                              true,
                              contatoAtivo.chatid
                            )}
                          </h6>
                          {contatoAtivo.chatid.split('@')[0].length < 20 &&
                          contatoAtivo.nome ? (
                            <IntlTelInput
                              fieldId="mobile"
                              containerClassName="intl-tel-input"
                              inputClassName="form-control form-control-sm"
                              value={telNumber()}
                              onFlagClick={() => copyPhone()}
                              disabled={true}
                              allowDropdown={false}
                              format={true}
                            />
                          ) : (
                            <strong>(Grupo)</strong>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-xl-flex d-none" style={{ flex: 1 }}>
                    <div className="d-flex align-items-center justify-content-center">
                      <AvatarEdit className="mr-5">
                        <div className="avatar avatar-sm d-xl-inline-block">
                          <Avatar dados={contatoAtivo} />
                        </div>
                        <span onClick={() => atualizarFoto()}>
                          <i className="fe-refresh-cw"></i>
                        </span>
                      </AvatarEdit>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ gap: '10px' }}
                      >
                        <div className="d-flex flex-column justify-content-center">
                          <h6
                            className="text-truncate text-left"
                            id="contatoNome"
                          >
                            {restringirNome(
                              contatoAtivo.nome,
                              19,
                              true,
                              contatoAtivo.chatid
                            )}
                          </h6>
                          {contatoAtivo.chatid &&
                          contatoAtivo.chatid.split('@') &&
                          contatoAtivo.chatid.split('@')[0].length < 20 ? (
                            contatoAtivo.nome !== 'undefined' &&
                            contatoAtivo.nome !== '' && (
                              <IntlTelInput
                                fieldId="contatoWhatsapp"
                                containerClassName="intl-tel-input intl-tel-input-ctt"
                                inputClassName="form-control form-control-sm"
                                value={telNumber()}
                                onFlagClick={() => copyPhone()}
                                disabled={true}
                                allowDropdown={false}
                                format={true}
                              />
                            )
                          ) : (
                            <small
                              style={{ fontWeight: 500, lineHeight: '1.4' }}
                            >
                              (Grupo)
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Chat toolbar */}
                  <div className="text-right pr-5 pr-lg-0">
                    <ul className="nav justify-content-end align-items-center">
                      <li className="nav-item list-inline-item d-none d-xl-block mr-3">
                        <div
                          className="d-flex align-items-center justify-content-center flex-wrap customBorderLeft"
                          style={{ gap: '20px' }}
                        >
                          {contatoAtivo &&
                            Object.values(contatoAtivo).length &&
                            contatoAtivo.marcadores &&
                            Object.values(contatoAtivo.marcadores)?.map(
                              (id, index) =>
                                id &&
                                marcadores &&
                                marcadores[id] && (
                                  <Tooltip
                                    key={index}
                                    text={marcadores[id].titulo}
                                  >
                                    <Marcador cor={marcadores[id].cor}>
                                      <i className="fas fa-bookmark"></i>
                                      <span>
                                        <i
                                          onClick={() => handleRemoveTag(id)}
                                          className="fe fe-x"
                                        />
                                      </span>
                                    </Marcador>
                                  </Tooltip>
                                )
                            )}
                          <div className="dropdown">
                            <Tooltip
                              text={'Adicione um marcador para este contato'}
                              position="right"
                            >
                              <Marcador
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                cor={'#aab0b7'}
                                corIcon={'#fddb78'}
                                style={{ cursor: 'pointer' }}
                              >
                                <i className="fal fa-bookmark"></i>
                                <span>
                                  <i className="fe fe-plus" />
                                </span>
                              </Marcador>
                            </Tooltip>
                            <div className="dropdown-menu mr-5">
                              {marcadores &&
                                Object.values(marcadores).length !== 0 &&
                                Object.values(marcadores).map((item, index) => {
                                  if (!item) return null;
                                  if (
                                    contatoAtivo.marcadores &&
                                    contatoAtivo.marcadores.includes(item.id)
                                  )
                                    return null;
                                  return (
                                    <div
                                      className="mb-2"
                                      key={index}
                                      onClick={() => handleAddTag(item.id)}
                                    >
                                      <Tag
                                        cor={item.cor}
                                        idTag={item.id}
                                        select
                                      >
                                        <span>{item.titulo}</span>
                                      </Tag>
                                    </div>
                                  );
                                })}
                              <div
                                className="mb-2"
                                data-toggle="modal"
                                data-target="#marcadores"
                              >
                                <Tag cor={'#49c18f'} select>
                                  <span>{t('Criar Marcador')}</span>
                                </Tag>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item list-inline-item d-none d-xl-block mr-2">
                        <div className="dropdown">
                          {
                            // eslint-disable-next-line
                            contatoAtivo.chatid &&
                            contato[contatoAtivo.chatid] &&
                            contato[contatoAtivo.chatid].userchat &&
                            contato[contatoAtivo.chatid].userchat > 0 &&
                            userchat &&
                            userchat[contatoAtivo.userchat] ? (
                              <AtualAtendente
                                color={
                                  userchat[
                                    contato[contatoAtivo.chatid].userchat
                                  ].cor
                                }
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {
                                  userchat[
                                    contato[contatoAtivo.chatid].userchat
                                  ].nome
                                }
                                <span className="ml-auto fe-chevron-down" />
                              </AtualAtendente>
                            ) : (
                              <AtualAtendente
                                color={'var(--secondary)'}
                                colorText
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Adicionar Atendente
                                <span className="ml-2 fe-chevron-down" />
                              </AtualAtendente>
                            )
                          }
                          <div className="dropdown-menu mr-5 mt-4">
                            {listarAtendentes()}
                          </div>
                        </div>
                      </li>

                      <li className="nav-item list-inline-item d-none d-xl-block mr-0">
                        <a
                          className="nav-link text-muted px-3"
                          data-toggle="collapse"
                          data-target="#chat-1-search"
                          href="# "
                          title="Procurar"
                        >
                          <i className="icon-md fe-search" />
                        </a>
                      </li>
                      {/* <li className="nav-item list-inline-item d-none d-xl-block mr-0">
                        <a
                          className="nav-link text-muted px-3"
                          href="# "
                          data-chat-sidebar-toggle="#chat-1-info"
                          title="Extensão SAK"
                        >
                          <i className="icon-md fe-maximize" />
                        </a>
                      </li> */}
                      {/* Mobile nav */}
                      <li className="nav-item list-inline-item d-block d-xl-none d-flex align-items-center justify-content-center">
                        <div className="dropdown">
                          <a
                            className="nav-link text-muted px-0"
                            href="# "
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="icon-md fe-more-vertical" />
                          </a>
                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item d-flex align-items-center"
                              data-toggle="collapse"
                              data-target="#chat-1-search"
                              href="# "
                            >
                              Pesquisar{' '}
                              <span className="ml-auto pl-5 fe-search" />
                            </a>
                            <a
                              className="dropdown-item d-flex align-items-center"
                              href="# "
                              data-chat-sidebar-toggle="#chat-1-info"
                            >
                              Dados do Cliente
                              <span className="ml-auto pl-5 fe-more-horizontal" />
                            </a>
                            {contatoAtivo.chatid &&
                            contato[contatoAtivo.chatid] &&
                            contato[contatoAtivo.chatid].userchat &&
                            contato[contatoAtivo.chatid].userchat > 0 ? (
                              <a
                                className="dropdown-item d-flex align-items-center"
                                data-toggle="collapse"
                                data-target="#chat-atendente"
                                href="# "
                              >
                                Marcadores
                                <span className="ml-auto pl-5 fe-user" />
                              </a>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="collapse border-bottom px-lg-8" id="chat-atendente">
              <div className="container-fluid py-4 py-lg-6">
                <ErrorChat codeError={44}>
                  {contatoAtivo.chatid &&
                  contato[contatoAtivo.chatid] &&
                  contato[contatoAtivo.chatid].userchat &&
                  contato[contatoAtivo.chatid].userchat > 0
                    ? listarAtendentes()
                    : ''}
                </ErrorChat>
              </div>
            </div>
            <ErrorChat codeError={43}>
              {mensagens ? (
                <Conversa
                  lastMessage={lastMessage}
                  mensagens={mensagens[contatoAtivo.chatid]}
                  userchat={userchat}
                  inicial={inicial}
                  lojas={lojas}
                  loja={lojaAtivo}
                />
              ) : null}
            </ErrorChat>

            <div className="chat-footer">
              {inicial &&
                contatoAtivo.chatid &&
                contato[contatoAtivo.chatid] &&
                contato[contatoAtivo.chatid].fechado === '0' &&
                contato[contatoAtivo.chatid].userchat !==
                  userLogado.userchat && (
                  <div className="d-flex align-items-center justify-content-center mb-6">
                    <Assumir onClick={() => assumirConversa()}>
                      Assumir Conversa
                    </Assumir>
                  </div>
                )}
              {contatoAtivo.chatid &&
                contato[contatoAtivo.chatid] &&
                contato[contatoAtivo.chatid].fechado > 0 &&
                contato[contatoAtivo.chatid].userchat !==
                  userLogado.userchat && (
                  <div className="d-flex align-items-center justify-content-center mb-6">
                    <Assumir onClick={() => assumirConversa()}>
                      {/* ReassumirConversa */}
                      Assumir Conversa
                    </Assumir>
                  </div>
                )}
              <div className="d-flex align-items-center justify-content-between">
                <ul
                  className="p-0 nav nav-tabs bg-transparent"
                  id="myTab"
                  role="tablist"
                >
                  <Tabs className="nav-item">
                    <a
                      className="nav-link active"
                      id="Publica"
                      onClick={() => setTab('Publica')}
                      data-toggle="tab"
                      href="#resposta-publica"
                      role="tab"
                      aria-controls="resposta-publica"
                      aria-selected="true"
                    >
                      {t('Resposta Pública')}
                    </a>
                  </Tabs>
                  <Tabs className="nav-item">
                    <a
                      className="nav-link"
                      id="Privada"
                      onClick={() => setTab('Privada')}
                      data-toggle="tab"
                      href="#anotacao-interna"
                      role="tab"
                      aria-controls="anotacao-interna"
                      aria-selected="false"
                    >
                      {t('Anotação Interna')}
                    </a>
                  </Tabs>
                </ul>
                <ErrorChat codeError={40}>
                  {/*  eslint-disable-next-line eqeqeq */}
                  {contatoAtivo && contatoAtivo.arquivado == '0' && (
                    <EncerrarConversa
                      href="# "
                      className="dropdown-item d-inline-flex w-auto"
                      onClick={() => ArquivarContato()}
                      color={'#FF5848'}
                    >
                      {t('Encerrar Conversa')}
                    </EncerrarConversa>
                  )}
                </ErrorChat>
              </div>
              <div className="hide-scrollbar flex-fill">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id={tab}
                    role="tabpanel"
                    aria-labelledby={tab + '-tab'}
                  >
                    <div className="chat-files hide-scrollbar pr-3 pl-3">
                      <div>
                        <div className="dropzone-previews-js form-row py-4 form-row-spacing pr-5" />
                      </div>
                    </div>
                    <div
                      className="chat-files px-lg-4 d-none"
                      id="dropzone-preview-img"
                    >
                      <div className="container-fluid">
                        <div className="form-row py-7 justify-content-center form-row-spacing">
                          Arraste seus arquivos e solte aqui.
                        </div>
                      </div>
                    </div>
                    {showEmojis && (
                      <div data-emoji-element ref={emojiRef}>
                        <NimblePicker
                          i18n={translateEmojis}
                          set="twitter"
                          data={listaEmojis}
                          showPreview={false}
                          onSelect={(emoji) => {
                            if (!inputTextRef.current) return;
                            inputTextRef.current.value += emoji['native'];
                            inputTextRef.current.focus();
                          }}
                        />
                      </div>
                    )}
                    {showDelayMessage && tab === 'Privada' && <DelayMessage />}
                    {showRepositoryBox && tab === 'Publica' ? (
                      <ImageRepository />
                    ) : null}
                    <form
                      onSubmit={handleSubmitMensagem}
                      id="chat-id-1-form"
                      data-emoji-form
                    >
                      <Anotacao background={tab === 'Privada' && 'anotacao'}>
                        <div className="d-flex align-items-center w-100 pt-3 pb-3">
                          <button
                            className={`btn btn-ico btn-secondary btn-minimal ${
                              showEmojis ? 'active' : ''
                            } bg-transparent border-0 ml-3`}
                            type="button"
                            ref={emojiBtn}
                            onClick={(_) =>
                              setShowEmojis(showEmojis ? false : true)
                            }
                          >
                            <img
                              src="assets/images/smile.svg"
                              data-inject-svg
                              alt="emoji"
                            />
                          </button>
                          <button
                            id="chat-upload-btn-1"
                            className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 dropzone-button-js"
                            type="button"
                          >
                            <img
                              src="assets/images/paperclip.svg"
                              data-inject-svg
                              alt="dropzone"
                            />
                          </button>
                          {tab === 'Publica' && (
                            <Tooltip text="Galeria">
                              <button
                                type="button"
                                className={`btn btn-ico btn-secondary btn-minimal ${
                                  showRepositoryBox ? 'active' : ''
                                } bg-transparent border-0`}
                                onClick={() =>
                                  setshowRepositoryBox(!showRepositoryBox)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-upload-cloud"
                                >
                                  <polyline points="16 16 12 12 8 16" />
                                  <line x1="12" y1="12" x2="12" y2="21" />
                                  <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3" />
                                  <polyline points="16 16 12 12 8 16" />
                                </svg>
                              </button>
                            </Tooltip>
                          )}

                          {tab === 'Publica' && <RecordAudio />}

                          {tab === 'Privada' && (
                            <button
                              type="button"
                              className={`btn btn-ico btn-secondary btn-minimal ${
                                showDelayMessage ? 'active' : ''
                              } bg-transparent border-0`}
                              onClick={(_) =>
                                setShowDelayMessage(
                                  showDelayMessage ? false : true
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-calendar"
                              >
                                <rect
                                  x="3"
                                  y="4"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                />
                                <line x1="16" y1="2" x2="16" y2="6" />
                                <line x1="8" y1="2" x2="8" y2="6" />
                                <line x1="3" y1="10" x2="21" y2="10" />
                              </svg>
                            </button>
                          )}

                          <TextArea
                            ref={inputTextRef}
                            id="chat-id-1-input"
                            onKeyPress={handleTextSend}
                            onChange={handleTextChange}
                            placeholder={t('Digite aqui a sua mensagem.')}
                            data-emoji-input
                            data-autosize="true"
                            name={`Conversa-${contatoAtivo.chatid}`}
                          ></TextArea>
                          <button type="submit">
                            <span className="fe-send" />
                          </button>
                        </div>
                      </Anotacao>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="chat-1-info" className="chat-sidebar chat-sidebar-visible">
            <div className="d-flex h-100 flex-column">
              <div className="hide-scrollbar flex-fill">
                <div id="sak">
                  <button id="sakClose" data-chat-sidebar-toggle="#chat-1-info">
                    <i className="fe-chevron-left"></i>
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Conteúdo onde fica os arquivos upados */}
      <div id="dropzone-template-js" style={{ display: 'none' }}>
        <div className="col-lg-4 my-3">
          <div className="card" style={{ background: 'var(--basic)' }}>
            <div className="card-body p-3">
              <div className="media align-items-center">
                <div className="dropzone-file-preview">
                  <div className="avatar avatar rounded bg-secondary text-basic-inverse d-block mr-5">
                    <i className="fe-paperclip" />
                  </div>
                </div>
                <div className="dropzone-image-preview">
                  <div className="avatar avatar mr-5">
                    <img
                      src="# "
                      className="avatar-img rounded"
                      data-dz-thumbnail
                      alt=""
                    />
                  </div>
                </div>
                <div className="media-body overflow-hidden">
                  <p className="extra-small" data-dz-size />
                </div>
                <div className="ml-5">
                  <a
                    href="# "
                    className="btn btn-sm btn-link text-decoration-none text-muted"
                    data-dz-remove
                  >
                    <i className="fe-x" />
                  </a>
                </div>
              </div>
            </div>
            <div className="progress-dropzone">
              <div
                className="progress-dropzone-bar"
                role="progressbar"
                data-dz-uploadprogress
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaixaMensagem;
