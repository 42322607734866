import React, { createContext, useContext, useState } from 'react';

const MensagemRapidaContext = createContext();

export default function MensagemRapidaProvider({ children }) {
  const [textMessage, setTextMessage] = useState('');

  return (
    <MensagemRapidaContext.Provider value={{ textMessage, setTextMessage }}>
      {children}
    </MensagemRapidaContext.Provider>
  );
}

export function useMensagemRapida() {
  const context = useContext(MensagemRapidaContext);
  const { textMessage, setTextMessage } = context;

  return { textMessage, setTextMessage };
}
