import styled, { keyframes } from 'styled-components';

export const BoxUser = styled.div`
  background-color: ${(props) => (props.color ? props.color : '#49c18f')};
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  width: 100%;
  height: 100%;

  color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
  font-weight: bold;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.8;
  transition: all 0.3s;

  img {
    width: 25px;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  border: 6px solid var(--border);
  border-radius: 50%;
  border-top: 6px solid var(--button-color);
  width: 75px;
  height: 75px;
  animation: ${rotate} 2s linear infinite;
`;

export const LoadingChat = styled.div`
  margin-top: 20px;
  background: var(--secondary);
  width: 30vw;
  min-width: 500px;
  height: 6px;
  position: relative;
  border-radius: 4px;

  &:before {
    transition: width 3s ease-in-out;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: ${(props) => (props.percent ? props.percent + '%' : '0%')};
    background: var(--button-color);
  }
`;

export const BackgroundChat = styled.div`
  display: ${(props) => (props.display ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  margin: 20px;
  text-align: center;

  img {
    max-width: 700px;
    width: 40vw;
    margin-bottom: 20px;
  }

  span {
    font-weight: bold;
    font-size: 2.2rem;
    color: var(--basic-inverse);
  }

  p {
    font-size: 1.4rem;
    color: #525058;
  }

  h5 {
    font-size: 1.6rem;
    line-height: 1.2;
    margin: 0;
  }

  h6 {
    margin: 0;
    font-weight: 400;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const Tabs = styled.li`
  position: relative;

  &:after {
    background-color: #ff5848;
    width: 22px;
    height: 22px;
    z-index: 1;

    position: absolute;
    top: -7px;
    right: -8px;
    font-size: 11px;

    border-radius: 12px;
    border: 1px solid white;

    pointer-events: none;
    font-weight: bold;
    color: white;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    content: '${(props) => props.notif && props.notif}';
    display: ${(props) => (props.notif && props.notif > 0 ? 'flex' : 'none')};
  }

  a {
    padding: 0.7rem 1rem;
    font-weight: bold;
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid transparent !important;
  }

  .active {
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid #171a31 !important;
  }
`;
