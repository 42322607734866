import { useMemo, useEffect, useState } from 'react';
import { ContainerRespostasRapidas } from './styles';
import { useMensagemRapida } from '../../hooks/rapidaMsgContext';
import { normalizeText } from '../../utils/formatDados';

function RespostasRapidas() {
    const { textMessage, setTextMessage } = useMensagemRapida();
    const [showShort, setShowShort] = useState(false);

    function updateTextShortcut(e, text) {
      e.preventDefault();

      const findContainerText = document.getElementById('chat-id-1-input');
      if(!findContainerText) return;

      findContainerText.value = text;
      setTextMessage('');
    }

    useEffect(() => {
      const handleCloseShorts = (event) =>
        event.keyCode === 27 && setShowShort(false);

      document.addEventListener('keyup', handleCloseShorts);

      if(textMessage && textMessage?.length) {
        setShowShort(true);
      }

      return () => {
        document.removeEventListener('keyup', handleCloseShorts);
      };
    }, [textMessage, setTextMessage]);

    const formatShortCuts = useMemo(() => {
        if(!textMessage || !textMessage.includes('/')) return null;

        let shortCuts = window?.SAKshortcut || {};

        if(!Object.values(shortCuts)?.length) return null;

        const newShorts = {};

        Object.entries(shortCuts).forEach(([key, value]) => {
          const exist = normalizeText(key, 'lower').indexOf(
            normalizeText(textMessage, 'lower')
          ) !== -1;
          if(!exist) return;
          newShorts[key] = value;
        })

        return newShorts;
    }, [textMessage])

    return formatShortCuts && showShort ? (
        <ContainerRespostasRapidas>
            {Object.entries(formatShortCuts)?.map(([key, value], index) => (
                <li onClick={e => updateTextShortcut(e, value)} key={index}>
                    <p>/<strong>{key}</strong> <span>{value}</span></p>
                </li>
            ))}
        </ContainerRespostasRapidas>
    ) : null
}

export {RespostasRapidas};
