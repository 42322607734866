import { useState, useMemo } from 'react';
import { ContainerMessages, ContainerContatos } from './styles';

import { useContatos, useLojaAtiva } from '../../hooks/dadosSak';
import Contato from '../../contents/Contato';
import useDadosSakStore from '../../stores/useDadosSakStore';
import { useTranslation } from 'react-i18next';

function MensagensAntigas() {
  // const { contatos } = useContatos();
  // const { lojaAtivo } = useLojaAtiva();
  // const { globalLoading } = useGlobalLoading();
  const [visibleContacts, setVisibleContacts] = useState(false);
  const [totalNaoLidas, setTotalNaoLidas] = useState(0);
  const { t, i18n } = useTranslation();

  const contatos = useDadosSakStore((state) => state.contatos);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);

  const formatContatos = useMemo(() => {
    const oneDay = 1000 * 60 * 60 * 24;
    const teenDays = ~~((Date.now() - oneDay * 10) / 1000);
    const dados = contatos[lojaAtivo];
    if (!dados || (dados && !Object.values(dados).length)) return [];

    const listContatos = [];
    let totalNaoLidas = 0;

    for (let index in dados) {
      if (Number(dados?.[index]?.naolidas) === 0) continue;
      if (dados?.[index]?.ultimamsg > teenDays) continue;
      listContatos[index] = dados[index];
      totalNaoLidas++;
    }

    setTotalNaoLidas(totalNaoLidas);
    return Object.values(listContatos)?.sort((a, b) =>
      a.ultimamsg > b.ultimamsg ? -1 : 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lojaAtivo, contatos]);

  return formatContatos.length ? (
    <>
      {visibleContacts && (
        <ContainerContatos>
          <span>
            <i
              onClick={(_) =>
                setVisibleContacts(visibleContacts ? false : true)
              }
              className="fe-chevron-left"
            ></i>
            <h5>{t('Mensagens Antigas')}</h5>
          </span>
          <div className="tab-scroll">
            {formatContatos?.map((contato, key) => (
              <Contato key={key} contato={contato} />
            ))}
          </div>
        </ContainerContatos>
      )}
      <ContainerMessages
        onClick={(_) => setVisibleContacts(visibleContacts ? false : true)}
      >
        <i className="fe-message-square"></i>
        <p>{t('Mensagens Antigas')}</p>
        <span>{totalNaoLidas}</span>
      </ContainerMessages>
    </>
  ) : (
    ''
  );
}

export default MensagensAntigas;
