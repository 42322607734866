import { Container, BadgesContainer } from './styles';
import TimeBadge from '../TimeBadge';

const DelayMessage = () => {
  return (
    <Container>
      <BadgesContainer>
        <div style={{ height: '35.69px' }}>
          <TimeBadge value="Customizado" />
        </div>
        <TimeBadge value="1H" timeType="H" quantity="1" />
        <TimeBadge value="3H" timeType="H" quantity="3" />
        <TimeBadge value="6H" timeType="H" quantity="6" />
        <TimeBadge value="1D" timeType="D" quantity="1" />
        <TimeBadge value="3D" timeType="D" quantity="3" />
        <TimeBadge value="7D" timeType="D" quantity="7" />
        <TimeBadge value="10D" timeType="D" quantity="10" />
        <TimeBadge value="1 MÊS" timeType="month" quantity="1" />
      </BadgesContainer>
    </Container>
  );
};

export default DelayMessage;
