import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  border-radius: 500px;
  color: #898989;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 4px 8px;
  min-height: 34px;
  min-width: 34px;
  display: flex;
  align-items: center;

  &.active {
    background: #00e673;
    color: #212529;
    border: 1px solid #00e673;
  }

  .react-datepicker__input-container {
    & > input {
      background: transparent;
      outline: none;
      border: none;
      cursor: pointer;
      width: 95px;
    }
  }
`;
