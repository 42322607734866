import styled from 'styled-components';

export const AudioControl = styled.div`
  display: flex;
  align-items: center;
  transition: 500ms ease-in-out;
  height: calc(1.6em + 1.375rem + 2px);
  min-width: calc(1.6em + 1.375rem + 2px);

  & button {
    background: none;
    border: none;
    outline: none;

    & > svg {
      /* stroke: #5b5e61af; */
      /* stroke-width: 1.5; */
    }
  }
`;

export const RecordButton = styled.button`
  transition: 500ms ease-in-out;
  cursor: pointer;
  /* opacity: ${(props) => (props.isRecording ? 0 : 1)};
  visibility: ${(props) => (props.isRecording ? 'hidden' : 'visible')}; */
  display: ${(props) => (props.isRecording ? 'none' : 'block')};
  height: 48px;
  width: 48px;
`;

export const StopButton = styled.button`
  transition: 500ms ease-in-out;
  cursor: pointer;
  /* opacity: ${(props) => (props.isRecording ? 1 : 0)};
  visibility: ${(props) => (props.isRecording ? 'visible' : 'hidden')}; */
  display: ${(props) => (props.isRecording ? 'block' : 'none')};
  height: 48px;
  width: 48px;
`;

export const AudioPlayerWrapper = styled.div`
  opacity: ${(props) => (props.hide ? 0 : 1)};
  width: ${(props) => (props.hide ? '0px' : 'unset')};

  & > audio {
    width: 171px;
    transform: translateX(-14px);
  }
`;
