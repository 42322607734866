import React, { useRef, useMemo } from 'react';
import { Tag, Badge } from './styles';

import {
  restringirNome,
  formatTime,
  verData,
  formatPhoneNumber,
} from '../../utils/formatDados';

import Avatar from '../../components/avatar';

import {
  useContatoAtivo,
  useMarcadores,
  useAtendente,
  useLojaAtiva,
} from '../../hooks/dadosSak';

import Tooltip from '../Tooltip';

import CacheStore from '../../utils/generateCache';
import useDadosSakStore from '../../stores/useDadosSakStore';
import useSocketStore from '../../stores/useSocketStore';
import useMarcarLidoStore from '../../stores/useMarcarLidoStore';

export default function Contato({ contato, lastMessage, temMensagemNaoLida }) {
  // const { setContatoAtivo } = useContatoAtivo();
  // const { marcadores } = useMarcadores();
  // const { userChat } = useAtendente();
  // const { lojaAtivo } = useLojaAtiva();
  const contatoUser = useRef(null);
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );
  const userChat = useDadosSakStore((state) => state.userChat);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const marcadores = useDadosSakStore((state) => state.marcadores);
  const setShowMarcarLidoBtn = useMarcarLidoStore(
    (state) => state.setShowMarcarLidoBtn
  );
  const toggleMarcarLidoBoxAndCheckbox = useMarcarLidoStore(
    (state) => state.toggleMarcarLidoBoxAndCheckbox
  );
  const setCheckboxMarcadosComoLidoQuantity = useMarcarLidoStore(
    (state) => state.setCheckboxMarcadosComoLidoQuantity
  );

  const playWhatsappAudio = () => {
    const isCheckboxReceiveMessageActive =
      JSON.parse(localStorage.getItem('checkboxOnReceiveMessageSound')) ||
      false;

    if (isCheckboxReceiveMessageActive) {
      if (
        (Number(contato.naolidas) > 0 || temMensagemNaoLida) &&
        !document.hasFocus()
      ) {
        let audio = new Audio('assets/WhatsAppTune.mp3');
        let promise = audio.play();
      }
    }
  };

  const handlePlayAudioCache = () => {
    let cacheStore = new CacheStore();
    cacheStore.remember('notification-sound-1', 12, () => {
      playWhatsappAudio();
      return 1;
    });
  };

  const flagMessageIsMe = useMemo(() => {
    handlePlayAudioCache();

    let statusAtual = '3';
    if (
      !lastMessage ||
      typeof lastMessage != 'object' ||
      !Number(lastMessage?.['me'])
    )
      return;

    const contatoNaoLidas = contato['naolidas']
      ? String(contato['naolidas'])
      : 0;

    const naoLida = Object.values(lastMessage)
      .sort((a, b) => (a.data_whats > b.data_whats ? 1 : -1))
      .slice(
        Number.isInteger(Number(contatoNaoLidas)) ? -Number(contatoNaoLidas) : 0
      )
      .map((item) => item.texto);
    if (naoLida.includes(lastMessage.texto)) statusAtual = '2';
    if (contatoNaoLidas === '0' || contatoNaoLidas === '-1') statusAtual = '3';

    return (
      <img
        className="mr-2"
        width={15}
        src={
          process.env.PUBLIC_URL +
          'assets/msgStatus' +
          (lastMessage.me === '1' ? lastMessage.status : statusAtual) +
          '.svg'
        }
        loading="lazy"
        alt={`status${lastMessage.status}`}
      />
    );
  }, [lastMessage, contato]);

  const listaMarcadores = useMemo(() => {
    const allMarcadores = marcadores && marcadores[lojaAtivo];

    return (
      allMarcadores &&
      contato.marcadores &&
      contato.marcadores?.map(
        (id, index) =>
          id &&
          allMarcadores[id] && (
            <Tag key={index} cor={allMarcadores?.[id]?.cor}>
              {allMarcadores?.[id] ? allMarcadores?.[id]?.titulo : ''}
            </Tag>
          )
      )
    );
  }, [marcadores, lojaAtivo, contato.marcadores]);

  function TrocarChat() {
    const clearChatMessage = () => {
      const textAreaInput = document.getElementById('chat-id-1-input');
      if (textAreaInput) {
        textAreaInput.value = '';
      }
    };
    clearChatMessage();

    const chat = document.getElementById('main-chat');
    const convs = document.querySelectorAll('#chat-tab');

    const findExtensao = document.querySelector('#chat-1-info');

    const findExtensaoSearch = document.getElementById('sakBusca');
    const findExtensaoContent = document.querySelector('#sak .tab-content');
    const convId = contatoUser.current;

    const ref = convId?.dataset?.['ref'];

    if (findExtensaoSearch && ref) {
      let numeroContato = '+' + ref?.split('@')?.[0]?.toString();
      if (numeroContato) {
        numeroContato = formatPhoneNumber(numeroContato);
        findExtensaoSearch.value = numeroContato;
      }
    }

    if (findExtensaoContent) {
      findExtensaoContent.innerHTML = `
        <div class="sak--load">
          <span></span>
          <h4>Carregando, aguarde!</h4>
        </div>
      `;
    }

    convs &&
      Array.from(convs).some((item) =>
        item.classList.remove('border-selected')
      );
    convId && convId.classList.add('border-selected');
    chat && chat.classList.add('main-visible');
    findExtensao &&
      window.innerWidth < 1200 &&
      findExtensao.classList.contains('chat-sidebar-visible') &&
      findExtensao.classList.remove('chat-sidebar-visible');

    setContatoAtivo(() => contato);
  }

  return (
    <div className="d-flex">
      {toggleMarcarLidoBoxAndCheckbox ? (
        <input
          type="checkbox"
          name="checkbox_marcar_lido"
          style={{ margin: '0 8px' }}
          onChange={(e) => {
            const values = Array.from(
              document.querySelectorAll('[name="checkbox_marcar_lido"]:checked')
            ).map((el) => el.value);
            setCheckboxMarcadosComoLidoQuantity(values?.length);
            const atLeastOneChecked =
              document.querySelectorAll('[name="checkbox_marcar_lido"]:checked')
                .length > 0;
            if (atLeastOneChecked) {
              setShowMarcarLidoBtn(true);
            } else {
              setShowMarcarLidoBtn(false);
            }
          }}
          value={
            contato['chatid']
              ? contato['chatid']
              : contato['conversa_id']
              ? contato['conversa_id']
              : ''
          }
        ></input>
      ) : null}

      <a
        className="text-reset nav-link p-0 sak--contato"
        style={{ width: '100%' }}
        id="chat-tab"
        onClick={() => TrocarChat()}
        href="#chat"
        role="tab"
        aria-controls="chat"
        aria-selected="false"
        data-ref={
          contato['chatid']
            ? contato['chatid']
            : contato['conversa_id']
            ? contato['conversa_id']
            : ''
        }
        ref={contatoUser}
      >
        <div
          className="card card-active-listener bg-transparent rounded-0 border-0"
          style={{ position: 'relative' }}
        >
          <div className="card-body pt-0 pb-0 mt-3 mb-3 pl-4 pr-5">
            <div
              className="d-flex align-items-center"
              style={
                String(contato?.arquivado) !== '0' ? { opacity: '0.45' } : {}
              }
            >
              <div className="avatar mr-5 position-relative">
                <Avatar dados={contato} />
              </div>
              <div className="media-body overflow-hidden">
                <div className="d-flex align-items-center justify-content-center mb-1">
                  <div
                    className="sak--contato-infos d-flex flex-column mr-auto"
                    style={{ maxWidth: '200px', wordBreak: 'break-word' }}
                  >
                    <h6 className="text-truncate mr-3 mb-0">
                      {restringirNome(contato.nome, null, null, contato.chatid)}
                    </h6>

                    <span
                      className="d-flex align-items-center"
                      title={contato?.['ultimamsgtexto'] || ''}
                    >
                      {flagMessageIsMe}
                      {contato['ultimamsgtexto'] === ' '
                        ? '<i className="fe fe-link mr-2"></i> Anexo'
                        : contato['ultimamsgtexto']?.replace('/n', ' ')}
                    </span>

                    <div
                      className="d-flex flex-wrap align-items-center justify-content-start"
                      style={{ gap: '2px' }}
                    >
                      {listaMarcadores}
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    {contato.chatid && contato['ultimamsg'] && (
                      <span
                        style={{ fontSize: '0.8rem' }}
                        className="small text-muted text-nowrap text-right"
                        id={'mensagem_' + contato.chatid}
                        onMouseEnter={() =>
                          verData(contato.chatid, contato['ultimamsg'], 1)
                        }
                        onMouseOut={() =>
                          verData(contato.chatid, contato['ultimamsg'], 0)
                        }
                      >
                        {formatTime(contato['ultimamsg'])}
                      </span>
                    )}

                    <div className="mt-2 d-inline-flex align-items-center justify-content-end mt-3">
                      {contato.naolidas &&
                        contato.naolidas !== '0' &&
                        contato.naolidas !== '-1' && (
                          <Badge>
                            {contato.naolidas > 99 ? '+99' : contato.naolidas}
                          </Badge>
                        )}
                      {userChat && userChat?.[contato?.userchat] && (
                        <Tooltip
                          text={
                            userChat[contato.userchat] &&
                            userChat[contato.userchat]?.nome
                              ? userChat[contato.userchat]?.nome
                              : 'SAK'
                          }
                        >
                          <Badge
                            data-for="main"
                            data-tip={
                              userChat?.[contato.userchat] &&
                              userChat?.[contato.userchat]?.nome
                                ? userChat?.[contato.userchat]?.nome
                                : 'SAK'
                            }
                            hasuserChat
                            color={
                              userChat?.[contato?.userchat]?.cor
                                ? userChat?.[contato?.userchat]?.cor
                                : '#ccc'
                            }
                          >
                            {restringirNome(
                              userChat?.[contato?.userchat] &&
                                userChat?.[contato?.userchat]?.nome
                                ? userChat?.[contato?.userchat]?.nome
                                : 'SAK',
                              1
                            )}
                          </Badge>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
