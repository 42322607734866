import styled from 'styled-components';

export const ContainerRespostasRapidas = styled.ul`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  list-style: none;
  gap: 5px;
  background: var(--secondary);
  z-index: 8;
  padding: 0;
  margin-bottom: 0;

  max-height: 50%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #63cab2;
  }

  li {
    padding: 13px 25px;
    display: flex;
    width: 100%;
    font-size: 0.9rem;
    cursor: pointer;

    &:hover {
      background: var(--light-bold);
    }

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--dark-bg);
      font-weight: 500;

      strong {
        font-weight: 500;
        color: var(--basic-inverse);
      }

      span {
        font-size: 0.8rem;
        font-weight: 400;
        margin-left: 15px;
        margin-bottom: 0;
        color: var(--dark-bg);
        line-height: initial;
      }
    }
  }
`;
