import React, { useRef, useEffect } from 'react';

import { Container } from './style';
// import { useImageExpand } from '../../hooks/imageExpand';

import LazyLoad from 'react-lazyload';
import useImageExpand from '../../stores/useImageExpand';

function ExpandImage() {
  // const { imageExpand, setImageExpand } = useImageExpand();
  const refLoadImage = useRef();

  const imageExpand = useImageExpand((state) => state.imageExpand);
  const setImageExpand = useImageExpand((state) => state.setImageExpandStore);

  useEffect(() => {
    if (!imageExpand) return;
    const handleCloseImage = (event) =>
      event.keyCode === 27 && setImageExpand(() => '');

    document.addEventListener('keyup', handleCloseImage);

    return () => {
      document.removeEventListener('keyup', handleCloseImage);
    };
  }, [imageExpand, setImageExpand]);

  const removeLoadImage = () => refLoadImage.current.remove();

  return (
    imageExpand && (
      <Container>
        <div onClick={() => setImageExpand(() => '')}></div>
        <i className="fe fe-x" onClick={() => setImageExpand(() => '')}></i>
        <input type="checkbox" id="zoomCheck" />
        <img src={`${process.env.PUBLIC_URL}assets/logo.png`} alt="logo-sak" />
        <span ref={refLoadImage} />
        <label htmlFor="zoomCheck">
          <LazyLoad>
            <img
              onLoad={removeLoadImage}
              onError={removeLoadImage}
              src={imageExpand}
              alt="sakimage"
            />
          </LazyLoad>
        </label>
      </Container>
    )
  );
}

export { ExpandImage };
