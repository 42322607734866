import React from 'react';

import Tip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { useTranslation } from 'react-i18next';

function Tooltip({ text, position = 'top', children }) {
  const { t, i18n } = useTranslation();
  return text ? (
    <Tip placement={position} overlay={<span>{t(text)}</span>}>
      {children}
    </Tip>
  ) : (
    children
  );
}

export default Tooltip;
