import { ErrorBoundary } from 'react-error-boundary';
// import fs from 'fs';

// const codeErrors = {
//   1: 'Falha ao carregar o chat',
//   2: 'Falha ao carregar foto',
//   3: 'Falha ao carregar dados de contatos',
//   4: 'Falha ao carregar lojas',
//   5: 'Falha ao carregar mensagens',
//   6: 'Falha ao responder mensagem',
//   7: 'Falha ao carregar informações',
//   8: 'Falha ao carregar dados gerais',
//   9: 'Falha ao carregar todos os contatos da loja',
//   10: 'Falha ao carregar dados das notificações da lista de contatos',
//   11: 'Falha ao carregar marcadores',
//   12: 'Falha ao carregar número do contato',
//   13: 'Falha ao carregar foto',

// };

function ErrorFallback(erro, codeError) {
  const existError = codeError ? `| Erro: ${codeError}` : '';
  return (
    <div
      className="alert alert-danger alert-dismissible fade show m-4"
      role="alert"
    >
      <strong>Ops!</strong> Parece que algo deu errado 😭 {existError}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

const logError = (error, info) => {
  //   fs.appendFile(
  //     'reactErrosFront.txt',
  //     `${new Date()} - error: ${error} | info: ${info}`
  //   );
};

function ErrorChat({ children, codeError }) {
  return (
    <ErrorBoundary
      FallbackComponent={(e) => ErrorFallback(e, codeError)}
      onError={logError}
    >
      {children}
    </ErrorBoundary>
  );
}

export { ErrorChat };
