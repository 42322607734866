import ModalDeleteMessage from '../ModalDeleteMessage';
import { Wrapper } from './styles';
import { useTranslation } from 'react-i18next';

const DropdownMensagem = ({ id, mensagens }) => {
  const { t, i18n } = useTranslation();
  return (
    <Wrapper>
      <div
        className="dropdown dropdown--mensagem"
        style={{
          position: 'absolute',
          top: '4px',
          right: '4px',
          zIndex: 9,
        }}
      >
        <button
          className="btn btn-secondary dropdown-toggle remove-dropdown-arrow"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src="assets/down-arrow.png"
            alt="dropdown"
            style={{ width: '20px', filter: 'opacity(0.6)' }}
          ></img>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
          <div
            className="deletar--mensagem dropdown-item"
            type="button"
            data-toggle="modal"
            data-target={`#modalDeleteMessage-${id}`}
            data-backdrop="false"
          >
            {t('Apagar mensagem')}
          </div>
        </div>
      </div>
      <ModalDeleteMessage id={id} mensagens={mensagens} />
    </Wrapper>
  );
};

export default DropdownMensagem;
