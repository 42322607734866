import { create } from 'zustand';

const useMarcarLidoStore = create((set) => ({
  showMarcarLidoBtn: false,
  setShowMarcarLidoBtn: (value) => set({ showMarcarLidoBtn: value }),
  toggleMarcarLidoBoxAndCheckbox: false,
  setToggleMarcarLidoBoxAndCheckbox: (value) =>
    set({ toggleMarcarLidoBoxAndCheckbox: value }),
  checkboxMarcadosComoLidoQuantity: 0,
  setCheckboxMarcadosComoLidoQuantity: (value) =>
    set({ checkboxMarcadosComoLidoQuantity: value }),
}));

export default useMarcarLidoStore;
