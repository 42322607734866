import React from 'react';

import { useError, useLojaAtiva, usePrint } from '../../hooks/dadosSak';
import useDadosSakStore from '../../stores/useDadosSakStore';

function Errors() {
  // const { onError } = useError();
  // const { lojaAtivo } = useLojaAtiva();
  // const { printWpp } = usePrint();

  const onError = useDadosSakStore((state) => state.onError);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const printWpp = useDadosSakStore((state) => state.printWpp);

  const iconErrors = {
    info: 'lds-dual-ring',
  };

  return Object.values(onError)
    .filter(
      (erro, index, array) =>
        array.findIndex(
          (err) =>
            err.id === erro.id &&
            err.userserv === erro.userserv &&
            err.tipo === erro.tipo
        ) === index
    )
    .map((erro, index) => {
      if (
        (erro.userserv === String(lojaAtivo) || erro.userserv === '0') &&
        erro.ativo === '1'
      ) {
        return (
          <div
            key={index}
            className={`d-flex rounded-0 pt-3 pb-3 align-items-center bg-${erro.tipo}`}
            style={{ color: 'rgb(56,54,49)' }}
          >
            <div
              className="ml-4 mr-1 p-4 bg-white rounded-circle d-flex align-items-center justify-content-center"
              style={{
                height: '45px',
                width: '45px',
                flexShrink: '0',
              }}
            >
              <i
                className={
                  iconErrors[erro.tipo] ? iconErrors[erro.tipo] : 'fe-phone-off'
                }
              />
            </div>
            <div className="d-flex flex-column p-4">
              <strong>{erro.titulo}</strong>
              {erro.mensagem}
              {erro.id === 'conexao_whatsapp' && (
                <a href="# " onClick={printWpp} className="text-dark">
                  Print do Whatsapp
                </a>
              )}
            </div>
          </div>
        );
      }
      return null;
    });
}

export default Errors;
