import React from 'react';

import DadosSakProvider from './hooks/dadosSak';
import SocketProvider from './hooks/socket-novo';
import ImageExpandProvider from './hooks/imageExpand';
import './lang/front/i18n';

import Home from './Home';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ValidateAbas from './utils/ValidateAbas';

import { ErrorChat } from './components/errors';

window.notifChat = (text, type) => {
  const acceptedTypes = ['loading', 'success', 'info', 'warning', 'error'];
  if (acceptedTypes.includes(type) && toast[type]) toast[type](text);
  else toast(text, { draggable: true, progress: undefined });
};

function App() {
  const tokenURL = window.location.href.split('/')[3].split('#')[0];
  let init =
    localStorage.inicializacao && JSON.parse(localStorage.inicializacao);

  const initDebug = localStorage['debug'];

  if (tokenURL) {
    if (init && tokenURL !== init.token) localStorage.clear();
    localStorage.setItem('inicializacao', JSON.stringify({ token: tokenURL }));
    window.location.replace('/');
  }

  return (
    <ErrorChat codeError={1}>
      {/* <DadosSakProvider> */}
      <ValidateAbas />

      <SocketProvider token={init && init['token']} debug={initDebug} />
      {/* <ImageExpandProvider> */}
      <ToastContainer />
      <Home />
      {/* </ImageExpandProvider> */}

      {/* </DadosSakProvider> */}
    </ErrorChat>
  );
}

export default App;
