/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

// var initial_language = { code: 'pt-BR', country_code: 'pt-BR' };
// var user = { id: undefined, language: 'pt-BR' };
const CountrySelect = () => {
  const { t, i18n } = useTranslation();
  const [localization, setLocalization] = useState([]);
  const [selectLanguage, setSelectLanguage] = useState('pt-BR');

  const ArrayLanguage = [
    {
      name: 'Português',
      code: 'pt-BR',
      flag: `${process.env.PUBLIC_URL}assets/flags/br.svg`,
    },
    {
      name: 'Español',
      code: 'es',
      flag: `${process.env.PUBLIC_URL}assets/flags/mx.svg`,
    },
  ];

  useEffect(() => {
    console.log('ArrayLanguage', ArrayLanguage);
    setLocalization(
      ArrayLanguage.map((localization) => ({
        value: localization.code,
        label: <img width="20" src={localization.flag} />,
      }))
    );
  }, []);

  const returnSelectedLanguage = () => {
    let labels_by_language = {
      BR: `${process.env.PUBLIC_URL}assets/flags/br.svg`,
      MX: `${process.env.PUBLIC_URL}assets/flags/mx.svg`,
      AR: `${process.env.PUBLIC_URL}assets/flags/ar.svg`,
      CL: `${process.env.PUBLIC_URL}assets/flags/cl.svg`,
      CO: `${process.env.PUBLIC_URL}assets/flags/co.svg`,
    };
    return {
      value: 'pt-BR',
      label: <img width="20" src={labels_by_language['BR']} />,
    };
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: '18px',
    }),
  };

  return (
    <>
      {/* <select
        name=""
        id=""
        style={{
          background: 'transparent',
          borderRadius: '4px',
          borderColor: '#ccc',
          color: 'gray',
          marginRight: '8px',
        }}
        onChange={(event) => {
          console.log('onchange select', event.target.value);
          setSelectLanguage(event.target.value);
          i18n.changeLanguage(event.target.value);
        }}
      >
        {localization.map((el, index) => (
          <option
            key={index}
            value={el.value}
            checked={index === 0 ? true : false}
          >
            {el.value}
          </option>
        ))}
      </select> */}

      <Select
        options={localization}
        styles={customStyles}
        defaultValue={returnSelectedLanguage()}
        name="countrySelect"
        isSearchable={false}
        onChange={(val) => {
          setSelectLanguage(val.value);
          i18n.changeLanguage(val.value);
        }}
      />
    </>
  );
};
export default CountrySelect;
