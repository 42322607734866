function gamification(tipo) {
  let itens = localStorage.getItem('gamefication');

  if (itens) itens = JSON.parse(itens);
  else itens = [];

  if (itens && itens.find((item) => item === tipo)) return null;

  itens.push(tipo);

  itens = JSON.stringify(itens);

  localStorage.setItem('gamefication', itens);

  if (
    typeof window.submitdado === 'function' &&
    typeof window.base_url === 'string'
  )
    window.submitdado(
      {
        tarefa: tipo,
      },
      window.base_url + `Ajax/marcartarefaChat`
    );
}

export { gamification };
