import { create } from 'zustand';
import useDadosSakStore from './useDadosSakStore';

const useSocketStore = create((set, get) => ({
  loadStatus: false,
  setLoadStatusStore: (callback, shouldSpread = false) =>
    set((state) => ({
      loadStatus: shouldSpread ? callback(state.loadStatus) : callback(),
    })),
  socketAtualStatus: 'Desconectado',
  setSocketAtualStatusStore: (callback, shouldSpread = false) =>
    set((state) => ({
      socketAtualStatus: shouldSpread
        ? callback(state.socketAtualStatus)
        : callback(),
    })),
  timeSocket: 250,
  setTimeSocketStore: (callback, shouldSpread = false) =>
    set((state) => ({
      timeSocket: shouldSpread ? callback(state.timeSocket) : callback(),
    })),
  delayMessage: null,
  setDelayMessageStore: (callback, shouldSpread = false) =>
    set((state) => ({
      delayMessage: shouldSpread ? callback(state.delayMessage) : callback(),
    })),
  sendSocketDados: () => {},
  setSendSocketDados: (callback) => {
    set(() => ({
      sendSocketDados: callback,
    }));
  },
}));

export default useSocketStore;
