import styled from 'styled-components';

export const Wrapper = styled.div`
  & div {
    .dropdown-toggle.remove-dropdown-arrow {
      background: none;
      outline: none;
      border: none;
      padding: 0;
      background: #d4f7c8;
      border-radius: 50%;

      &::after {
        display: none;
      }
    }
  }
`;
