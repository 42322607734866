import styled from 'styled-components';

export const Container = styled.section`
  & > div {
    max-height: 390px;
    overflow: auto;
    padding: 16px;
    padding-bottom: 0;
  }

  //FILEPOND RESETS
  .filepond--drop-label {
    cursor: pointer;
    transform: translate3d(0px, 0px, 0px) !important;
    opacity: 1 !important;
    visibility: visible !important;
    pointer-events: all !important;

    & > label {
      cursor: pointer !important;
    }
  }

  .filepond--drip {
    background-color: #fafafa !important;
    border: 2px dashed #bdc3c7 !important;
    border-radius: 8px;
    opacity: 0.4;
  }

  .filepond--root {
    height: 81px !important;
    width: 200px;
  }

  .filepond--list {
    display: none;
  }

  .filepond--panel-root {
    background-color: #fafafa !important;
    border: 2px dashed #bdc3c7 !important;
  }

  /* .filepond--wrapper {
    max-width: 518px;
  } */

  .filepond--list-scroller {
    display: none;
  }

  .filepond--credits {
    display: none;
  }

  .filepond--drop-label {
    height: inherit !important;
    cursor: pointer;
  }

  .filepond--label-action {
    display: flex;
    flex-direction: column;
    align-items: center;

    opacity: 0.5;
    text-decoration: none;

    & > img {
      width: 32px;
      filter: hue-rotate(45deg);
    }
  }
`;

export const ContentImgs = styled.div`
  position: relative;
  & > button {
    padding: 4px;
    width: 24px;
    height: 24px;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 9;

    & > img {
      width: 13px;
    }
  }
  &:hover {
    & > button {
      display: flex;
    }
  }
`;

export const ContainerImgs = styled.div`
  margin-bottom: 16px;

  & .container--imgs-repository {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(84px, 1fr));
    gap: 0.5rem;
  }

  & .content--imgs-repository {
    position: relative;
    cursor: pointer;

    & > img {
      width: 100%;
      height: 84px;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
    }

    /* & > button {
      padding: 4px;
      width: 24px;
      height: 24px;
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 9;

      & > img {
        width: 13px;
      }
    } */

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-3px);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 13px;
      font-weight: 500;
      opacity: 0;
      transition: 200ms ease;
    }

    /* &:hover {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000042;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 500;
        border: 2px solid #00d600;
        opacity: 1;
        transform: translateY(0px);
      }

      & > button {
        display: flex;
      }
    } */
  }
`;

export const FileName = styled.strong`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 11px;
  line-height: 1.1;
`;
