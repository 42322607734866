import React from 'react';
import Tooltip from '../../../../Tooltip';

export default function ZoomRepositoryImage({ id, url }) {
  return (
    <>
      <Tooltip text="Ver imagem" position="right">
        <button
          type="button"
          className="btn btn-primary"
          style={{ top: '33px' }}
          data-toggle="modal"
          data-target={`#modalRepositoryImageZoom-${id}`}
          data-backdrop="false"
          onClick={(e) => {
            e.stopPropagation();
            const $ = window.jQuery;
            $(`#modalRepositoryImageZoom-${id}`).modal('show');
            $(`.modal-backdrop`).css('width', '0');
          }}
        >
          <img
            src="assets/images/eye.svg"
            alt="ver"
            style={{ filter: 'invert(1)' }}
          />
        </button>
      </Tooltip>

      <div
        className="modal fade modalRepositoryImageZoom"
        id={`modalRepositoryImageZoom-${id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`modalRepositoryImageZoomLabel-${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className="modal-content"
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              position: 'relative',
            }}
          >
            <div>
              <button
                type="button"
                class="close"
                style={{
                  opacity: 1,
                  background: '#00000094',
                  borderRadius: '50%',
                  height: '30px',
                  color: 'white',
                  width: '30px',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 1,
                }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={url} alt={url} width="100%" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
