import { t } from 'i18next';

function formatTime(tempo) {
  if (!tempo) return '';
  tempo = Number(tempo);
  const atualTimestamp = ~~(new Date().getTime() / 1000);
  const diferencaAgoraXMensagem = atualTimestamp - tempo;
  const maxHourDay = 86400;

  const dias = ~~(diferencaAgoraXMensagem / maxHourDay);
  const date = new Date(tempo * 1000);

  const dataMensagem = date.getDate();
  const dataAtual = new Date().getDate();
  const dataAtualDiffDataMensagem = dataMensagem !== dataAtual;

  if (!dataAtualDiffDataMensagem)
    return `${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}`;
  else if (dias <= 7)
    return dias === 1 ? t('ontem') : dias + ' ' + t('dias atrás');
  else if (dias >= 7) return mostrarData(tempo);
  else return mostrarData(tempo);
}

function formatTimeMensagens(tempo) {
  if (!tempo) return;
  tempo = Number(tempo);
  const date = new Date(tempo * 1000);

  return `${('0' + date.getHours()).slice(-2)}:${(
    '0' + date.getMinutes()
  ).slice(-2)}`;
}

function formatDataHistorico(tempo) {
  if (!tempo) return tempo;
  tempo = Number(tempo);
  const date = new Date(tempo * 1000); // Convert seconds to milliseconds
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) return t('Hoje');
  else if (date.toDateString() === yesterday.toDateString()) return t('ontem');
  else return mostrarData(tempo);
}

// function formatDataHistorico(tempo) {
//   if (!tempo) return tempo;
//   tempo = Number(tempo);

//   const tempoAtual = ~~(new Date().getTime() / 1000);
//   const diff = tempoAtual - tempo;
//   const maxHourDay = 86400;
//   const dias = ~~(diff / maxHourDay);

//   if (dias === 0) return 'Hoje';
//   else if (dias <= 7) return dias === 1 ? 'ontem' : dias + ' dias atrás';
//   else return mostrarData(tempo);
// }

function mostrarData(tempo) {
  if (!tempo) return tempo;
  tempo = Number(tempo);
  const dataAtual = new Date(tempo * 1000);

  const parsedMonth = String(dataAtual.getMonth() + 1).padStart(2, '0');
  const parsedDay = String(dataAtual.getDate()).padStart(2, '0');

  return `${parsedDay}/${parsedMonth}/${dataAtual.getFullYear()}`;
}

function restringirNome(nome, limite = 0, pontos = false, phone = 0) {
  const checkNameIsPhoneNumber = /^-?\d+$/;
  if (nome && checkNameIsPhoneNumber.test(nome)) {
    return formatPhoneNumber(nome);
  } else if (
    (!nome && phone && phone !== 0) ||
    (nome && nome === 'undefined')
  ) {
    phone = '+' + phone.split('@')[0].toString();
    return formatPhoneNumber(phone);
  } else if (limite) {
    return nome.length && nome.length > limite
      ? nome.substr(0, limite) + (pontos ? '...' : '')
      : nome;
  } else {
    return nome;
  }
}

function verData(id, data, type) {
  const element = document.getElementById('mensagem_' + id);
  type === 0
    ? (element.innerText = formatTime(data))
    : (element.innerText = mostrarData(data));
}

const formatPhoneNumber = (str) => {
  const cleaned = ('' + str).replace(/\D/g, '');

  const match = cleaned.match(/^(55)(\d{2})(?:(\d{1})?)(\d{4})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+55 ' : '';
    return [
      intlCode,
      '(',
      match[2],
      ') ',
      match[3] ? match[3] : '',
      match[4],
      '-',
      match[5],
    ].join('');
  }

  return `+${cleaned}`;
};

function mergeDeep(...objects) {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

function normalizeText(text, transform = null, format = '') {
  if (transform === 'upper') text = text.toUpperCase();
  else if (transform === 'lower') text = text.toLowerCase();

  if (format === 'spaces')
    text = text.normalize('NFKD').replace(/([^A-Za-z0-9 _]])/g, '');
  else
    text = text.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
  return text;
}

function checkIsPhoneNumber9AndNot9Exist(first, second) {
  first = first.replace(/\s+/g, '');
  second = second.replace(/\s+/g, '');

  if (first === second) return 1; // 100%
  if (first.length < 2 || second.length < 2) return 0; // 0%

  let firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2);
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

function parseVcard (vcardText) {
  const vcardLines = vcardText.split('\n');
  let contact = {};
  
  for (const line of vcardLines) {
    if (line.startsWith('BEGIN:VCARD')) {
      contact = {};
    } else if (line.startsWith('END:VCARD')) {
      return contact;
    } else if (line.includes(':')) {
      const [key, value] = line.split(':');
      if (key === 'FN') {
          contact.fullName = value;
      } else if (key.startsWith('item1.TEL;') || key.startsWith('TEL;')) {
        const numberInfo = key.split(';');
        const phoneNumber = value   //.replace(/\s+/g, '');
        const waIdMatch = numberInfo[1]?.startsWith('type')
          ? numberInfo[2]?.match(/waid=(\d+)/)
          : numberInfo[1].match(/waid=(\d+)/);

        if (waIdMatch) {
          const waId = waIdMatch[1];
          contact.whatsappId = waId;
          contact.phoneNumber = phoneNumber;
        }
      }
    }
  }
}

export {
  mergeDeep,
  formatPhoneNumber,
  verData,
  formatTime,
  formatTimeMensagens,
  formatDataHistorico,
  mostrarData,
  restringirNome,
  normalizeText,
  asyncForEach,
  checkIsPhoneNumber9AndNot9Exist,
  parseVcard
};
