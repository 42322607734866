export const ContatosPagination = ({
  contatosPage,
  setContatosPage,
  mensagens,
  paginationQuantity,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '16px',
        gap: '0.5rem',
      }}
    >
      {contatosPage > 0 && (
        <button
          type="button"
          className="btn btn-primary"
          style={{
            color: 'black',
            background: 'no-repeat',
            outline: 'none',
            border: 'none',
            padding: 0,
          }}
          onClick={() => setContatosPage((prev) => (prev <= 0 ? 0 : prev - 1))}
        >
          {'<'}
        </button>
      )}

      {Math.ceil(Object.values(mensagens ?? {})?.length) >
        paginationQuantity && (
        <input
          type="number"
          value={contatosPage + 1}
          style={{
            width: '50px',
            borderRadius: '10px',
            border: '1px solid #a7a7a7',
          }}
          onChange={(e) => {
            const newValue = Number(e.target.value) - 1;
            setContatosPage(newValue);
          }}
          min={1}
          max={Math.ceil(
            Object.values(mensagens ?? {})?.length / paginationQuantity
          )}
        />
      )}

      {Math.ceil(Object.values(mensagens ?? {})?.length) / paginationQuantity >
        contatosPage + 1 && (
        <button
          type="button"
          className="btn btn-primary"
          style={{
            color: 'black',
            background: 'no-repeat',
            outline: 'none',
            border: 'none',
            padding: 0,
          }}
          onClick={() => setContatosPage((prev) => prev + 1)}
        >
          {'>'}
        </button>
      )}
    </div>
  );
};
