import styled from 'styled-components';

export const RecuperarMensagensAntigas = styled.div`
  text-align: center;
  padding-bottom: 20px;
  font-weight: 500;
  color: var(--basic-inverse);
  margin-bottom: 25px;
  font-size: 1rem;
  position: relative;
  
  span {
    opacity: ${props => props.desativado ? '0.6' : 1};
    padding: 8px 16px;
    border-radius: 3px;
    transition: all 0.3s;
    cursor: ${props => props.desativado ? 'auto' : 'pointer'};

    &:hover {
      opacity: ${props => props.desativado ? '0.6' : 1};
      background: ${props => props.desativado ? 'unset' : 'var(--secondary)'};
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 16%;
    width: 70%;
    height: 1px;
    background: var(--secondary);
  }
`;

export const Container = styled.div`
  position: absolute;
  width: auto;
  right: 0;
  padding: 7px 15px;
  border-radius: 0 0px 0px 10px;
  background-color: var(--dark-bg);
  color: white;
  top: 0;
  font-weight: bold;
  font-size: 14px;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  span {
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    transition: color 0.2s;

    &:hover {
      color: #ccc;
    }
  }
`;

export const Resposta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light);

  div {
    background: var(--secondary);
    margin: 15px 18px 15px 50px;
    flex: 1;
    padding: 8px 10px;
    border-left: 3px solid rgb(107, 204, 164);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 3px;
    position: relative;

    strong {
      color: rgb(107, 204, 164);
    }

    span i {
    }

    i {
      top: 7px;
      right: 7px;
      position: absolute;
      font-size: 1rem;
      cursor: pointer;
    }
  }
`;
