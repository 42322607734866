import React, { useRef, useMemo } from 'react';
import reactStringReplace from 'react-string-replace';

import {
  restringirNome,
  formatTime,
  verData,
  formatPhoneNumber,
} from '../../utils/formatDados';

import useDadosSakStore from '../../stores/useDadosSakStore';

export default function MensagemBusca({ texto, mensagem, contato }) {
  const contatoUser = useRef(null);
  const setContatoAtivo = useDadosSakStore(
    (state) => state.setContatoAtivoStore
  );

  function TrocarChat() {
    const clearChatMessage = () => {
      const textAreaInput = document.getElementById('chat-id-1-input');
      if (textAreaInput) {
        textAreaInput.value = '';
      }
    };
    clearChatMessage();

    const chat = document.getElementById('main-chat');
    // const convs = document.querySelectorAll('#chat-tab');

    const findExtensao = document.querySelector('#chat-1-info');

    const findExtensaoSearch = document.getElementById('sakBusca');
    const findExtensaoContent = document.querySelector('#sak .tab-content');
    const convId = contatoUser.current;

    const ref = convId?.dataset?.['ref-chat'];

    if (findExtensaoSearch && ref) {
      let numeroContato = '+' + ref?.split('@')?.[0]?.toString();
      if (numeroContato) {
        numeroContato = formatPhoneNumber(numeroContato);
        findExtensaoSearch.value = numeroContato;
      }
    }

    if (findExtensaoContent) {
      findExtensaoContent.innerHTML = `
        <div class="sak--load">
          <span></span>
          <h4>Carregando, aguarde!</h4>
        </div>
      `;
    }

    // convs &&
    //   Array.from(convs).some((item) =>
    //     item.classList.remove('border-selected')
    //   );
    // convId && convId.classList.add('border-selected');
    chat && chat.classList.add('main-visible');
    findExtensao &&
      window.innerWidth < 1200 &&
      findExtensao.classList.contains('chat-sidebar-visible') &&
      findExtensao.classList.remove('chat-sidebar-visible');

    setContatoAtivo(() => contato);

    // TEMP
    irParaMensagem(mensagem);
  }

  const flagMessageStatus = useMemo(() => {
    if (
      !mensagem ||
      typeof mensagem != 'object' ||
      !Number(mensagem?.['me'])
    ) return;

    return (
      <img
        className="mr-2"
        width={15}
        src={
          process.env.PUBLIC_URL +
          'assets/msgStatus' + mensagem.status + '.svg'
        }
        loading="lazy"
        alt={`status${mensagem.status}`}
      />
    );
  }, [mensagem]);

  function irParaMensagem(mensagem) {
    // TEMP - não ter que mexer no useeffect
    setTimeout(() => {
      const conv = document.querySelectorAll('#msgConv');
      if (!conv) return;
  
      conv.forEach((item, index) => {
        if (item.dataset.id === mensagem.id) {
          conv[index] &&
            conv[index].scrollIntoView({ block: 'center', behavior: 'smooth' });
          item.classList.add('message-active');
          setTimeout(() => item.classList.remove('message-active'), 2000);
        }
      });
    }, 500);
  }

  const highlighted = reactStringReplace(
    texto?.replace('/n', ' '),
    /(\*[^*]+?\*)/g,
    (match, i) => (<strong key={match + i}>{match.replace(/[*]/g, '')}</strong>)
  );

  return (
    <div className="d-flex">
      <a
        className="text-reset nav-link p-0 sak--contato"
        style={{ width: '100%' }}
        id="chat-tab"
        onClick={() => TrocarChat()}
        href="#chat"
        role="tab"
        aria-controls="chat"
        aria-selected="false"
        data-ref=""
        data-ref-chat={contato['chatid'] || ''}
        ref={contatoUser}
      >
        <div
          className="card card-active-listener bg-transparent rounded-0 border-0"
          style={{ position: 'relative' }}
        >
          <div className="card-body pt-0 pb-0 mt-3 mb-3 pl-4 pr-5">
            <div
              className="d-flex align-items-center"
            >
              <div className="media-body overflow-hidden">
                <div className="d-flex align-items-center justify-content-center mb-1">

                  <div
                    className="sak--contato-infos d-flex flex-column mr-auto"
                    style={{ maxWidth: '200px', wordBreak: 'break-word' }}
                  >
                    <h6 className="text-truncate mr-3 mb-0">
                      {restringirNome(contato.nome, null, null, contato.chatid)}
                    </h6>

                    <span
                      className="d-flex align-items-center"
                      title={texto || ''}
                    >
                      <div className='d-block'>
                        {flagMessageStatus}
                        {highlighted}
                      </div>
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    {mensagem.id && (
                      <span
                        style={{ fontSize: '0.8rem' }}
                        className="small text-muted text-nowrap text-right"
                        id={'mensagem_' + mensagem.id}
                        onMouseEnter={() =>
                          verData(mensagem.id, mensagem.data_whats, 1)
                        }
                        onMouseOut={() =>
                          verData(mensagem.id, mensagem.data_whats, 0)
                        }
                      >
                        {formatTime(mensagem.data_whats)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
