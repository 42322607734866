import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;

  /* .react-datepicker__input-container {
    visibility: hidden;
    height: 0;
    padding: 0;
    width: 0;
    position: absolute;
  }

  .react-datepicker-popper {
    transform: translate(8px, -119px) !important;

    @media (max-width: 472px) {
      transform: translate(8px, -162px) !important;
    }
  } */
`;

export const BadgesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 16px;
  margin-left: 8px;
  margin-bottom: 16px;
`;
