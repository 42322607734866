import React, { useState, useEffect, useCallback } from 'react';

import { useStatusSocket } from '../hooks/dadosSak';
import useDadosSakStore from '../stores/useDadosSakStore';
import { t } from 'i18next';

export default function ValidateAbas() {
  // const { setStatusSocket } = useStatusSocket();
  const [status, setStatus] = useState(false);

  const setStatusSocket = useDadosSakStore(
    (state) => state.setStatusSocketStore
  );

  const atualizarAbas = useCallback(
    (event) => {
      if (event.key !== 'abasAberta') return null;
      if (Number(event.newValue) !== Number(event.oldValue)) {
        setStatus(true);
        setStatusSocket(() => false);
      } else {
        localStorage.setItem('abasAberta', Date.now());
        setStatus(false);
        setStatusSocket(() => true);
      }
    },
    [setStatusSocket]
  );

  useEffect(() => {
    window.addEventListener('storage', (e) => atualizarAbas(e));
    localStorage.setItem('abasAberta', Date.now());
  }, [atualizarAbas]);

  return status ? (
    <div
      id="msgRandom"
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: '100vh' }}
    >
      <div className="reload-app">
        <h2>
          {t(
            'o Chat do Sak foi aberto em outra janela do navegador! Clique em "usar aqui" para voltar a utilizar nesta janela.'
          )}
        </h2>
        <div className="d-flex align-items-center justify-content-end">
          <button
            onClick={() =>
              (window.location.href = 'https://app.sak.com.br/dashboard')
            }
          >
            {t('Sair')}
          </button>
          <button onClick={() => window.location.reload()}>
            {t('Usar aqui')}
          </button>
        </div>
      </div>
    </div>
  ) : (
    true
  );
}
