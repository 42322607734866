import { useEffect, useState, useRef } from 'react';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import useDadosSakStore from '../../../../stores/useDadosSakStore';
import { Container, ContainerImgs, ContentImgs, FileName } from './styles';

// Import the plugin code
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import useSocketStore from '../../../../stores/useSocketStore';
import { toast } from 'react-toastify';
import Tooltip from '../../../Tooltip';
import { throttle, getToken } from '../../../../utils/usefulFunctions';
import DeleteRepositoryImageModal from './modal/DeleteRepositoryImageModal';
import ZoomRepositoryImage from './modal/ZoomRepositoryImage';
import { t } from 'i18next';

// Register the plugin
registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

let throttledToast = throttle(toast.success, 1000);

export default function ImageRepository() {
  const filePondRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [currentUserservState, setCurrentUserservState] = useState(null);

  const [imageRepositoryState, setImageRepositoryState] = useState([]);

  const contatoAtivo = useDadosSakStore((state) => state.contatoAtivo);
  const userLogado = useDadosSakStore((state) => state.userLogado);
  const lojaAtivo = useDadosSakStore((state) => state.lojaAtivo);
  const lojas = useDadosSakStore((state) => state.lojas);
  const sendSocketDados = useSocketStore((state) => state.sendSocketDados);

  const filePondLabel = () => {
    return `
		<div className="filepond--label-action">
			<img className="logo" src="assets/images/plus-circle.svg" alt="logo" />
      <h5>Adicionar imagens</h5>
		</div>
		`;
  };

  const handleAddImage = (e, file) => {
    e.stopPropagation();
    const { url, filename, mimetype } = file;

    // const idProvisorio = crypto.randomUUID();

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const imageFile = new File([blob], filename, { type: mimetype });
        window.myDropzoneGlobal.addFile(imageFile);
        toast.success('Arquivo adicionado');
      })
      .catch((error) => console.error(error));

    // sendSocketDados({
    //   function: 'enviarMensagem',
    //   data: [
    //     {
    //       idProvisorio,
    //       texto: '',
    //       conversa: contatoAtivo.chatid,
    //       userserv: lojaAtivo,
    //       anexo: [
    //         {
    //           filename: filename,
    //           idProvisorio: idProvisorio,
    //           mime: mimetype,
    //           status: 'ok',
    //           url: url,
    //           // uuid: idProvisorio,
    //         },
    //       ],
    //       userchat: userLogado.userchat,
    //       resposta: '',
    //     },
    //   ],
    // });

    // toast.success('Enviando mensagem pode levar alguns segundos...');
  };

  const sortRepositoryData = (data) => {
    const result = data.reduce((acc, obj) => {
      const { userserv, id, url, mimetype, filename, data } = obj;
      if (!acc[userserv]) {
        acc[userserv] = {
          images: [{ id, url, mimetype, filename, data }],
        };
      } else {
        acc[userserv].images.push({
          id,
          url,
          mimetype,
          filename,
          data,
        });
      }
      return acc;
    }, {});

    Object.keys(lojas).forEach((loja) => {
      if (!result[loja]) {
        result[loja] = { images: [] };
      }
    });

    const sortByLoja = Object.entries(result).sort(([key1], [key2]) =>
      key1 === lojaAtivo
        ? -1
        : key2 === lojaAtivo
        ? 1
        : key1.localeCompare(key2)
    );

    return sortByLoja;
  };

  const getRepositoryImages = () => {
    const token = getToken();
    fetch(
      `https://chat.sak.com.br/imagem.php?token=${token}&userchat=${userLogado.userchat}`
    )
      .then((res) => res.json())
      .then((data) => {
        setImageRepositoryState(sortRepositoryData(data));
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    getRepositoryImages();
  }, [userLogado.userchat]);

  const saveRepositoryImages = (file) => {
    const token = getToken();
    const image = [
      {
        base64: file.getFileEncodeDataURL(),
        filename: file.filename,
        mimetype: null,
        data: { filename: file.filename },
      },
    ];

    fetch('https://chat.sak.com.br/imagem.php', {
      method: 'POST',
      body: JSON.stringify({
        token: token,
        userchat: userLogado.userchat,
        userserv: currentUserservState,
        images: image,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        getRepositoryImages();
        throttledToast('Imagens salvas com sucesso!');
      })
      .catch((err) => alert(err));
  };

  const handleWhichThumbShouldRender = (file) => {
    const mimeType = file.mimetype.match(/^[^/]+/)[0];
    const extraData = file.data ? JSON.parse(file.data) : '';

    const html = {
      image: () => {
        return (
          <>
            <img
              src={file.url}
              alt="img"
              loading="lazy"
              onError={(e) => {
                e.target.src = 'assets/images/no-image.jpg';
              }}
            />
            <FileName>{extraData['filename']}</FileName>
          </>
        );
      },
      video: () => {
        return (
          <>
            <video
              style={{
                width: '100%',
                height: '84px',
                objectFit: 'cover',
                borderRadius: '4px',
              }}
              preload="metadata"
            >
              <source src={file.url + '#t=0.5'} type="video/mp4" />
            </video>
            <FileName>{extraData['filename']}</FileName>
          </>
        );
      },
      application: () => {
        return (
          <>
            <img
              src={'assets/images/doc-placeholder.png'}
              alt="img"
              loading="lazy"
              onError={(e) => {
                e.target.src = 'assets/images/no-image.jpg';
              }}
            />
            <FileName>{extraData['filename']}</FileName>
          </>
        );
      },
    };

    const notMappedMimetype = (
      <>
        <img
          src={'assets/images/doc-placeholder.png'}
          alt="img"
          loading="lazy"
          onError={(e) => {
            e.target.src = 'assets/images/no-image.jpg';
          }}
        />
        <FileName>{extraData['filename']}</FileName>
      </>
    );

    return html[mimeType]?.() || notMappedMimetype;
  };

  return (
    <Container>
      <div style={{ display: 'none' }}>
        <FilePond
          ref={filePondRef}
          files={files}
          onupdatefiles={setFiles}
          allowMultiple={true}
          maxFiles={20}
          maxFileSize={'5MB'}
          allowFileTypeValidation="true"
          // acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
          allowDrop={false}
          allowFileEncode
          onaddfile={(error, file) => {
            if (error?.main == 'File is too large') {
              file.abortLoad();

              toast.warn(`Coloque Arquivos de no máximo 5mb!`);
              toast.clearWaitingQueue();
            }
            if (error?.main == 'File is of invalid type') {
              file.abortLoad();
              toast.warn('Formato não suportado');
              toast.clearWaitingQueue();
            }
          }}
          onpreparefile={(file, output) => {
            throttledToast('Carregando Arquivos pode levar alguns segundos...');
            saveRepositoryImages(file);
          }}
          onwarning={(error) => {
            toast.warn('Coloque no máximo 20 Arquivos por vez!');
          }}
          name="files"
          labelIdle={filePondLabel()}
        />
      </div>
      <div>
        <h3
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            marginBottom: '0',
          }}
        >
          {t('Minha Galeria')} |
          <small
            style={{ color: '#a1a1a1', fontSize: '12px', fontWeight: '500' }}
          >
            {t('Salve arquivos para usar quando quiser ex: imagens,pdf,video')}
          </small>
        </h3>
        {imageRepositoryState.map((file) => {
          let currentUserserv = file[0];
          let config = file[1];

          return (
            <section key={currentUserserv}>
              <div>
                <h5
                  style={{
                    padding: '16px 0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {lojas[currentUserserv]?.nome}
                  <Tooltip text="Adicionar Arquivo ex: imagem,pdf,video">
                    <button
                      type="button"
                      style={{
                        background: 'none',
                        border: 'none',
                        outline: 'none',
                      }}
                      onClick={() => {
                        setCurrentUserservState(currentUserserv);
                        filePondRef.current.browse();
                      }}
                    >
                      <img
                        className="logo"
                        src="assets/images/plus-circle.svg"
                        alt="logo"
                      />
                    </button>
                  </Tooltip>
                </h5>
              </div>
              <ContainerImgs>
                <div className="container--imgs-repository">
                  {config.images.map((img) => (
                    <ContentImgs key={img.id}>
                      <div
                        className="content--imgs-repository"
                        onClick={(e) => handleAddImage(e, img)}
                      >
                        {handleWhichThumbShouldRender(img)}
                      </div>
                      <DeleteRepositoryImageModal
                        id={img.id}
                        getRepositoryImages={getRepositoryImages}
                      />
                      {img.mimetype.includes('image') ? (
                        <ZoomRepositoryImage id={img.id} url={img.url} />
                      ) : null}
                    </ContentImgs>
                  ))}
                </div>
              </ContainerImgs>
            </section>
          );
        })}
      </div>
    </Container>
  );
}
