import styled from 'styled-components';

export const Tag = styled.div`
  background: ${(props) => (props.cor ? props.cor : '#49c18f')};
  font-size: 10px;
  color: white;
  padding: 2px 12px;
  margin-right: 3px;
  border-radius: 20px;
`;

export const Badge = styled.div`
  background-color: ${(props) => (props.color ? props.color : '#49c18f')};
  border-radius: 50%;
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: UPPERCASE;

  color: white;
  font-weight: ${(props) => (props.hasuserchat ? 'normal' : 'bold')};
  font-size: 12px;

  margin-right: 5px;
`;

export const BadgeUser = styled.div`
  position: absolute;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  bottom: -2px;
  right: -4px;
  width: 18px;
  height: 18px;
  font-size: 0.5rem;
  background: ${(props) => (props.bg ? props.bg : '#ff5848')};
  border: 1px solid #f5f6fa;
`;
