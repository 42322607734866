import { useState, useEffect } from 'react';
import { useSocket } from '../../../../hooks/socket';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Container } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

import ptBr from 'date-fns/locale/pt-BR';
import useSocketStore from '../../../../stores/useSocketStore';
registerLocale('pt-BR', ptBr);

const TimeBadge = ({ value, timeType, quantity }) => {
  const setDelayMessage = useSocketStore((state) => state.setDelayMessageStore);
  // const { setDelayMessage } = useSocket();
  const [startDate, setStartDate] = useState(new Date());

  // useEffect(() => {
  //   const addDisabledToDateInput = () => {
  //     document
  //       .querySelector('.react-datepicker__input-container input')
  //       .setAttribute('disabled', true);
  //   };
  //   // addDisabledToDateInput();
  // }, []);

  const convertDateToSeconds = (date) => {
    setDelayMessage(() => date.getTime() / 1000);
  };

  const addDaysToDate = (days) => {
    let date = new Date();
    date.setDate(date.getDate() + Number(days));
    convertDateToSeconds(date);
  };
  const addHoursToDate = (hours) => {
    let date = new Date();
    date.setTime(date.getTime() + Number(hours) * 60 * 60 * 1000);
    convertDateToSeconds(date);
  };
  const addMonthsToDate = (months) => {
    let date = new Date();
    let newDate = new Date(date.setMonth(date.getMonth() + Number(months)));
    convertDateToSeconds(newDate);
  };

  const setCorrectDate = () => {
    if (timeType === 'D') {
      addDaysToDate(quantity);
    } else if (timeType === 'month') {
      addMonthsToDate(quantity);
    } else if (timeType === 'H') {
      addHoursToDate(quantity);
    }
  };

  const handleClick = (e) => {
    setCorrectDate();
    if (
      e.target.classList.contains('delay-message-badge') ||
      e.target.classList.contains('react-datepicker-ignore-onclickoutside')
    ) {
      Array.from(document.querySelectorAll('.delay-message-badge')).forEach(
        (el) => {
          const timeType = el.getAttribute('data-time-type');
          if (timeType === value) {
            el.classList.contains('active')
              ? el.classList.remove('active')
              : el.classList.add('active');
          } else {
            el.classList.remove('active');
          }
        }
      );
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <Container
      className="delay-message-badge"
      data-time-type={value}
      onClick={handleClick}
    >
      {value === 'Customizado' ? (
        <DatePicker
          showTimeSelect
          selected={startDate}
          onChange={(date) => {
            convertDateToSeconds(date);
            setStartDate(date);
          }}
          onCalendarClose={() => {
            document
              .querySelector('.delay-message-badge.active')
              ?.classList.remove('active');
            const el = document.querySelector(
              `.delay-message-badge[data-time-type="${value}"]`
            );
            el.classList.add('active');
          }}
          minDate={new Date()}
          filterTime={filterPassedTime}
          dateFormat="dd/MM/yyyy"
          locale="pt-BR"
        />
      ) : (
        value
      )}
    </Container>
  );
};

export default TimeBadge;
